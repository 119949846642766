<template>
    <ul>
        <li class="dropdown-content__value" v-for="locale in locales" :key="locale" @click="switchLocale(locale)">
            <a href="#">{{ locale.toUpperCase() }}</a>
        </li>
    </ul>
</template>
<script>
export default {
    name: 'LocalSwitcher',
    data() {
        return {
            locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
        }
    },

    methods: {
        switchLocale(locale) {
            if (this.$i18n.locale !== locale) {
                this.$i18n.locale = locale;
                localStorage.setItem('lang', locale)
                window.location.reload();
            }
        }
    }
}
</script>
<style scoped lang="scss">
.dropdown-content__value a {
    color: #717070;
    padding: 2px 0px;
    text-decoration: none;
    display: block;
    font-family: Gilroy-Regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.dropdown-content__value a:hover {
    color: #9747ff;
    transition: color 0.3s ease-in;
}

@media (min-width: 0px) and (max-width: 1290px) {
    .dropdown-content__value a {
        font-size: 10px;
    }
}
</style>

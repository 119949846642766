// Copyright 2023, Alexander Nekrasov, All rights reserved.
export class SettingBase {
    constructor(domain, name, type) {
        this.domain = domain;
        this.name = name;
        this.type = type;
        this._uninitialized = true;
    }
    get displayName() {
        return `${this.domain}Setting_${this.name}`;
    }
    get uninitialized() {
        return this._uninitialized;
    }
    get default() {
        return undefined;
    }
    get error() {
        return undefined;
    }
    MarkInitialized() {
        this._uninitialized = false;
    }
    Commit() {
        throw "not implemented";
    }
    Revert() {
        throw "not implemented";
    }
    // eslint-disable-next-line no-unused-vars
    fromDBJson(data) {
        throw "not implemented";
    }
    // eslint-disable-next-line no-unused-vars
    toDBJson(data, force) {
        throw "not implemented";
    }
}

// Copyright 2023, Alexander Nekrasov, All rights reserved.

import { Buffer } from "buffer";

function jsonFromBase64(input) {
  return JSON.parse(Buffer.from(input, "base64").toString() || "null");
}

export function decodeJWT(token) {
  const parts = token.split(".");

  const info = jsonFromBase64(parts[0]);
  const payload = jsonFromBase64(parts[1]);
  //const signature = parts[2];
  return {
    info,
    payload,
  };
}

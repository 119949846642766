// Copyright 2023, Alexander Nekrasov, All rights reserved.
/* global BigInt */
import { formatAccountId, formatFiatCurrency, urlFilterRegex } from "../format";
import { AccountSettings } from "./AccountSettings";
import { TokenSettings } from "./BlockchainSettings";
import { sharedState } from "../sharedState";
export class Message {
    constructor(id, accountSettings) {
        if (!(accountSettings instanceof AccountSettings))
            throw "InjectError";
        this.accountSettings = accountSettings;
        this.id = id;
        this.blockchain = undefined;
        this.fromAccountId = undefined;
        this.isCustomNickname = true;
        this.nickname = "";
        this.confirmedAt = new Date();
        this.replayAt = new Date();
        this.textProperty = "";
        this.rawValue = BigInt(0);
        this.fiatValue = {};
        this.currencyContract = undefined;
        this.tokenSettings = new TokenSettings();
        this.blockExplorerUrl = undefined;
        this.isRemoving = false;
        this.isReplaying = false;
        this.status = undefined; // Fake/Confirmed
    }
    get date() {
        return new Date(this.confirmedAt).toLocaleString(undefined, {
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
        });
    }
    get text() {
        const predicate = () => "<link>";
        return this.accountSettings.urlFilter ? this.textProperty.replace(urlFilterRegex, predicate) : this.textProperty;
    }
    get token() {
        return this.tokenSettings;
    }
    get ticker() {
        return this.token?.name;
    }
    get nativeValue() {
        if (this.token?.decimals !== undefined) {
            const value = Number(this.rawValue) / Math.pow(10, this.token.decimals);
            return `${value}`;
        }
        else {
            const value = Number(this.rawValue) + "n";
            return `${value}`;
        }
    }
    get uiValue() {
        if (this.fiatValue) {
            const cur = this.accountSettings.displayCurrency;
            const prefix = this.accountSettings.displayCurrencySymbol;
            const value = formatFiatCurrency(this.fiatValue[cur]);
            return `${prefix}${value}`;
        }
        else {
            return this.nativeValue;
        }
    }
    async UpdateNickname() {
        if (this.isCustomNickname)
            return; // ignore custom names;
        const nickname = await sharedState.nicknameCache.getNickname(this.fromAccountId);
        if (nickname !== undefined) {
            this.nickname = nickname;
        }
    }
    fromJson(messageData) {
        this.fromAccountId = messageData?.fromAccount;
        this.isCustomNickname = !!messageData?.nickname;
        this.nickname = messageData?.nickname || formatAccountId(this.fromAccountId);
        this.confirmedAt = new Date(messageData?.confirmedAt || 0);
        this.replayAt = new Date(messageData?.replayAt || 0);
        this.textProperty = messageData?.message || "";
        this.rawValue = BigInt(messageData?.rawValue);
        this.fiatValue = JSON.parse(JSON.stringify(messageData?.fiatValue || {}));
        this.currencyContract = messageData?.currencyContract;
        const chainType = messageData?.chainType;
        const chainId = messageData?.chainId;
        const chain = sharedState.settings.findChain(chainType, chainId);
        this.tokenSettings = chain?.findToken(this.currencyContract) || new TokenSettings();
        this.isRemoving = false;
        this.isReplaying = false;
        this.status = messageData?.status;
        this.blockchain = chain?.blockchain;
        if (this.status === "Confirmed" && this.id !== undefined) {
            this.blockExplorerUrl = chain?.blockExplorerUrlTemplate?.replace("<txHash>", this.id);
        }
    }
}

// Copyright 2023, Alexander Nekrasov, All rights reserved.
import { NotificationVariationRuntime } from "./NotificationVariationRuntime";
import { SettingColor } from "./SettingColor";
import { SettingSlider } from "./SettingSlider";
export const DOMAIN = "variation";
export class NotificationVariationBase {
    constructor(uuid, type, createdAt, manager) {
        if (!type || !type.length)
            throw "can't be empty string";
        this.uuid = uuid;
        this.type = type;
        this.createdAt = createdAt || new Date();
        this.manager = manager;
        this.alertDurationSecondsSetting = new SettingSlider(DOMAIN, "alertDurationSeconds", 5, 1, 20, 1);
        this.textDelaySecondsSetting = new SettingSlider(DOMAIN, "textDelaySeconds", 0, 0, 20, 1);
        this.textDurationSecondsSetting = new SettingSlider(DOMAIN, "textDurationSeconds", 5, 0, 20, 1);
        this.chromaColorSetting = new SettingColor(DOMAIN, "chromaColor", "#0000");
        this.isSaving = false;
        this.isTesting = false;
        this.isCommitted = false;
    }
    get alertDurationSeconds() {
        return this.alertDurationSecondsSetting.value;
    }
    get textDelaySeconds() {
        return this.textDelaySecondsSetting.value;
    }
    get textDurationSeconds() {
        return this.textDurationSecondsSetting.value;
    }
    get chromaColor() {
        return this.chromaColorSetting.value;
    }
    get runtime() {
        return new NotificationVariationRuntime(this);
    }
    get allSettings() {
        return [
            this.alertDurationSecondsSetting,
            this.textDelaySecondsSetting,
            this.textDurationSecondsSetting,
            this.chromaColorSetting,
        ];
    }
    get dirty() {
        if (!this.isCommitted)
            return true;
        for (let setting of this.allSettings) {
            if (setting.dirty)
                return true;
        }
        return false;
    }
    get valid() {
        return this.allSettings.reduce((prev, setting) => {
            if (!prev)
                return false;
            return !setting.error;
        }, true);
    }
    get enabled() {
        return true;
    }
    get displayName() {
        return "default";
    }
    get groupIndex() {
        return 1;
    }
    fromDBJson(data) {
        this.allSettings.forEach((setting) => {
            setting.fromDBJson(data);
        });
        this.isCommitted = true;
    }
    toDBJson(data) {
        data.enabled = true;
        this.allSettings.forEach((setting) => {
            setting.toDBJson(data, true);
        });
        data.uuid = this.uuid;
        data.type = this.type;
        data.createdAt = this.createdAt.toISOString();
    }
    Commit() {
        this.allSettings.forEach((setting) => {
            setting.Commit();
        });
        this.isCommitted = true;
    }
    MarkInitialized() {
        this.allSettings.forEach((setting) => {
            setting.MarkInitialized();
        });
    }
    async Save() {
        if (this.isSaving)
            return;
        this.isSaving = true;
        try {
            await this.manager.SaveVariation(this);
            this.Commit();
            this.isSaving = false;
        }
        catch (error) {
            this.isSaving = false;
            throw error;
        }
    }
    async Delete() {
        if (!this.isCommitted) {
            this.manager.ClientDeleteVariation(this.uuid);
            return;
        }
        if (this.isSaving)
            return;
        this.isSaving = true;
        try {
            await this.manager.DeleteVariation(this);
            this.isSaving = false;
            this.manager.ClientDeleteVariation(this.uuid);
        }
        catch (error) {
            this.isSaving = false;
            throw error;
        }
    }
    async Test() {
        if (!this.isCommitted)
            throw "not_committed"; // can't test uncommitted variation
        if (!this.enabled)
            throw "variation_disabled";
        if (this.isTesting)
            return;
        try {
            this.isTesting = true;
            await this.manager.TestVariation(this);
            this.isTesting = false;
        }
        catch (error) {
            this.isTesting = false;
            throw error;
        }
    }
    // eslint-disable-next-line no-unused-vars
    filter(message) {
        return true;
        //throw "not_implemented";
    }
    // eslint-disable-next-line no-unused-vars
    variationFactor(message) {
        return -1;
        //throw "not_implemented";
    }
}
NotificationVariationBase.Type = "default";

<template>
  <section id="main">
    <div class="main-overlay">
      <Burger :burger-visible="burgerVisible" :open-burger="openBurger" :user-are-loged="userAreLoged"
        :open-log-in="openLogIn" />

      <Header :open-burger="openBurger" :user-are-loged="userAreLoged" :open-log-in="openLogIn" />

      <Hero :user-are-loged="userAreLoged" :open-log-in="openLogIn" />
    </div>
  </section>
</template>
<script>
import Burger from "@/components/landing/burger.vue";
import Header from "@/components/landing/header.vue";
import Hero from "@/components/landing/hero.vue"

export default {
  components: { Burger, Header, Hero },

  data: () => ({
    burgerVisible: false,
    userAreLoged: ''
  }),

  watch: {
    "$firebase.account"() {
      this.Start();
    },
    "$donatrix.settingsReady"() {
      this.Start();
    },
  },

  mounted() {
    this.userAreLoged = this.$firebase.account ?? ''
  },

  methods: {
    openBurger() {
      if (!this.burgerVisible) {
        this.burgerVisible = true
      } else {
        this.burgerVisible = false
      }
    },

    openLogIn() {
      if (this.userAreLoged == '') {
        this.$store.commit('openLogin')
      } else {
        this.$router.push({ name: "dashboard" })
      }
    },

    Start() {
      if (!this.$donatrix.settingsReady || !this.$firebase.account) return;
      this.userAreLoged = this.$firebase.account ?? ''

    },
  },


}
</script>

<style scoped lang="scss">
#main {
  background: url("../../../public/images/mainBackground.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.main-overlay {
  background: linear-gradient(0deg, #07041B 0%, rgba(7, 4, 27, 0) 15%);
}

@media (max-width: 680px) {
  #main {
    background: url("../../../public/images/mainBackMobile.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  #main::after {
    display: none;
  }

  .main-overlay {
    position: relative;
    background: linear-gradient(0deg, #07041B 0%, rgba(7, 4, 27, 0.00) 120%);

  }

}
</style>
// Copyright 2023, Alexander Nekrasov, All rights reserved.

/* global BigInt */
import { GetEthers } from "./ethersChunk";
import { RecipientData } from "./classes/RecipientData";
import { LocalStorage } from "./classes/LocalStorage";
import { Firebase } from "./firebase";
import { WalletBase, WalletType } from "./classes/WalletBase";
import { WalletUserRejectedError } from "./classes/WalletError";
import { getFirebaseFirestore } from "./firebaseChunks";
import { sharedState } from "./sharedState";

export const DONATRIX_ERROR = Object.freeze({
  RequestFailed: "RequestFailed",
  InvalidAuth: "InvalidAuth", // must be authenticated
  SelfDonate: "SelfDonate", // can't donate to self
  NoActiveWallet: "NoActiveWallet", // no active wallet or connected address is unrelated to current account,
  InvalidRecipientAccount: "InvalidRecipientAccount", // recipient account is not found
  RecipientNoAddress: "RecipientNoAddress", // recipient has no address in selected chain
  LowAllowance: "LowAllowance", // valid for ERC-20 tokens
  LowBalance: "LowBalance",
  ZeroValue: "ZeroValue",
  InvalidAmount: "InvalidAmount", // number parse error
  LowAmount: "LowAmount", // amount entered is lower than allowed (by system or recipient settings)
  TooLongMessage: "TooLongMessage",
});

export class Donatrix {
  constructor(firebase) {
    if (!(firebase instanceof Firebase)) throw "InjectError";

    this.sharedState = sharedState;
    this.donatrixSettings = new LocalStorage("donatrixSettings");
    this.firebase = firebase;

    this.reloadSettingsHandle = undefined;
    this.settingsReady = false;

    this.selectedAmountProp = "";
    this.recipientData = new RecipientData();
    this.donateLimits = {};

    this.fromNicknameProp = "";
    this.customNickname = false;
    this.messageProp = "";
    this.isPendingDonate = false;
    this.isPendingApprove = false;

    this.donatrixError = undefined;

    this.ResetInternalState();
  }

  get settings() {
    return this.sharedState.settings;
  }

  get isSupportedChain() {
    //return true;
    //return this.donatrixAddress !== undefined;
    return this.donatrixAddress !== undefined || this.feeAddress !== undefined;
  }

  get selectedAmount() {
    return this.selectedAmountProp;
  }

  set selectedAmount(value) {
    if (typeof value !== "string") throw "expecting string";
    this.selectedAmountProp = value;
    this.OnAmountChanged();
  }

  get donateTo() {
    return this.recipientData.accountId;
  }

  get donateToAddress() {
    const recipientAddress = this.recipientData.getWalletAddress(this.sharedState.activeWallet);
    return recipientAddress;
  }

  get fromNickname() {
    return this.fromNicknameProp;
  }

  set fromNickname(value) {
    if (typeof value !== "string") throw "expecting string";
    this.fromNicknameProp = value;
  }

  get messageLimit() {
    const system = this.settings.messageLimit;
    const user = this.recipientData.accountSettings.messageLimit;
    const limit = user === undefined ? system : Math.min(user, system);
    return limit;
  }

  get message() {
    return this.messageProp;
  }

  set message(value) {
    if (typeof value !== "string") throw "expecting string";
    this.messageProp = value || "";
    this.Validate();
  }

  get serviceFee() {
    return this.settings.serviceFeePercent;
  }

  get serviceFeeUI() {
    return this.serviceFee ? `${this.serviceFee}%` : undefined;
  }

  PostConstruct() {
    const urlParams = new URLSearchParams(window.location.search);
    const recipientAccountId = urlParams.get("donateTo");
    this.LoadDonateToInfo(recipientAccountId);

    this.messageProp = urlParams.get("message") || "";
    //this.fromNicknameProp = urlParams.get("from") || "";
  }

  WalletChanged() {
    //to prevent double execution
    {
      const newType = this.sharedState.activeWallet?.type;
      const newChain = this.sharedState.activeWallet?.chainId;
      const newAddress = this.sharedState.activeWallet?.address;
      if (this.currentAddress === undefined && newAddress === undefined) return;
      if (newType === this.currentWalletType && newChain === this.currentChainId && newAddress === this.currentAddress)
        return;
      this.currentWalletType = newType;
      this.currentChainId = newChain;
      this.currentAddress = newAddress;
    }

    //const activeWallet = this.sharedState.activeWallet;
    //console.log("WalletChanged", activeWallet?.type, activeWallet?.chainId, activeWallet?.address);
    if (!this.settingsReady) return;
    this.ReconfigureInternalState();
  }

  ResetInternalState() {
    // network info
    this.network = undefined;
    this.supportedNetworks = [];
    this.supportedTokens = [];
    this.selectedToken = undefined;
    this.donatrixAddress = undefined; // for Ethereum and compatible
    this.feeAddress = undefined; // for Solana

    //balance of selected token
    this.rawAllowance = undefined;
    this.rawBalance = undefined;
    this.rawSelectedAmount = 0n;
    this.rawLimit = 0n;

    this.isUpdatingBalance = false;

    this.decimals = undefined;
    this.balance = undefined;
    this.allowance = undefined;
    this.canSpend = false;
    this.canApprove = false;
  }

  ReconfigureInternalState() {
    //console.log("reconfiguring");
    // TODO ??? cancel last update

    // reset current state
    this.ResetInternalState();
    const wallet = this.sharedState.activeWallet;
    if (!(wallet instanceof WalletBase)) {
      return;
    }

    // update supported networks
    let chains = [];
    if (wallet.type === WalletType.Ethereum) chains = this.settings.chains || [];
    else if (wallet.type === WalletType.Solana) chains = this.settings.solanaChains || [];
    this.supportedNetworks = chains;

    if (!wallet.chainId) {
      return;
    }
    const chain = chains.find((chain) => {
      return chain.chainId === wallet.chainId;
    });
    this.network = wallet.chainId ? chain?.blockchain || "Unsupported network" : "No network";
    this.donatrixAddress = chain?.donatrixAddress;
    this.feeAddress = chain?.feeAddress;

    // update supported tokens
    this.supportedTokens = (chain?.supportedTokens || []).map((token) => {
      token.isNative = token.address === wallet.zeroAddress;
      return token;
    });

    // update selected token
    // update balance/allowance
    const storageId = `prefTokenAddress::${wallet.type}::${wallet.chainId}`;
    const prefTokenAddress = this.donatrixSettings.get(storageId, wallet.zeroAddress);
    this.SelectToken(prefTokenAddress, false);

    // check destination address?
    this.Validate();
  }

  async LoadApplicationSettings() {
    const ls = new LocalStorage("blockchainSettings");
    const timestamp = ls.get("savedAt", undefined);
    const savedAt = new Date(timestamp);
    const utcNow = new Date();
    if (utcNow - savedAt < 60 * 1000) {
      return ls.get("value");
    }

    if (!this.firebase.initialized) {
      // firebase is not ready
      // wait for firebase.initialized
      return {};
    }

    const firestoreModule = await getFirebaseFirestore();
    const firestore = firestoreModule.getFirestore(this.firebase.app);
    const ref = firestoreModule.doc(firestore, "/settings/blockchain");
    const blockchain = await firestoreModule.getDoc(ref);
    if (!blockchain.exists()) {
      throw "failed to load application settings";
    }

    const blockchainSettings = blockchain.data();
    ls.set("savedAt", utcNow.toISOString());
    ls.set("value", blockchainSettings);
    return blockchainSettings;
  }

  async UpdateBlockchainSettings() {
    const blockchainSettings = await this.LoadApplicationSettings();
    console.log("loaded");
    this.settings.fromJson(blockchainSettings);
    this.settingsReady = true;

    // force reload settings periodically (for CI purposes and make sure client using latest actual settings from DB)
    // so we can rest assured that clients switches to new contract in 2h in worst case
    clearTimeout(this.reloadSettingsHandle);
    this.reloadSettingsHandle = setTimeout(() => {
      this.UpdateBlockchainSettings();
    }, 7200 * 1000);

    this.ReconfigureInternalState();
  }

  SelectToken(address, cachePref = true) {
    const wallet = this.sharedState.activeWallet;
    if (wallet instanceof WalletBase) {
      const addresses = this.supportedTokens.map((token) => token.address);
      const tokenAddress = addresses.includes(address) ? address : wallet.zeroAddress;
      if (cachePref) {
        const storageId = `prefTokenAddress::${wallet.type}::${wallet.chainId}`;
        this.donatrixSettings.set(storageId, tokenAddress);
      }
      this.selectedToken = this.supportedTokens.find((token) => {
        return token.address === tokenAddress;
      });
    }

    this.UpdateBalanceAndAllowance();
  }

  async UpdateBalanceAndAllowance() {
    const wallet = this.sharedState.activeWallet;
    this.isUpdatingBalance = true;
    this.decimals = this.selectedToken?.decimals || 0;
    if (!(wallet instanceof WalletBase) || !wallet.address || !this.selectedToken) {
      this.rawBalance = this.rawAllowance = this.balance = this.allowance = undefined;
      this.OnAmountChanged();
      this.isUpdatingBalance = false;
      return;
    }

    if (!(wallet instanceof WalletBase)) {
      console.log("bad active wallet");
      return;
    }

    try {
      if (!this.selectedToken.decimals) throw "decimals is not configured";

      if (this.selectedToken.address === wallet.zeroAddress) {
        this.rawBalance = this.rawAllowance = await wallet.GetBalance();
        this.balance = this.allowance = Number(this.rawBalance) / Math.pow(10, this.decimals);
      } else {
        this.rawBalance = await wallet.GetTokenBalance(this.selectedToken.address);
        if (this.donatrixAddress) {
          this.rawAllowance = await wallet.GetTokenAllowance(this.selectedToken.address, this.donatrixAddress);
        } else {
          // for networks without service contract, just set allowance equal to balance
          this.rawAllowance = this.rawBalance;
        }
        this.balance = Number(this.rawBalance) / Math.pow(10, this.decimals);
        this.allowance = Number(this.rawAllowance) / Math.pow(10, this.decimals);
      }
      this.OnAmountChanged();
      this.isUpdatingBalance = false;
    } catch (error) {
      console.error(error);
      this.rawBalance = this.rawAllowance = this.balance = this.allowance = undefined;
      // schedule retry
      setTimeout(this.UpdateBalanceAndAllowance.bind(this), 1000);
      return;
    }
  }

  FirebaseInitialized(initialized) {
    if (initialized) {
      this.UpdateBlockchainSettings();

      const urlParams = new URLSearchParams(window.location.search);
      const recipientAccountId = urlParams.get("donateTo");
      this.LoadDonateToInfo(recipientAccountId);
    }
  }

  async OnAmountChanged() {
    const newVal = this.selectedAmountProp;
    try {
      if (!newVal) throw "cant_be_empty";
      if (newVal <= 0) throw "must_be_positive";

      const ethers = await GetEthers();
      try {
        this.rawSelectedAmount = BigInt(ethers.utils.parseUnits(newVal, this.decimals));

        // also update limit
        const limit = this.donateLimits[this.selectedToken?.name] || 0;
        this.rawLimit = BigInt(ethers.utils.parseUnits(limit.toString(), this.decimals));
      } catch (error) {
        throw error.fault || "not_a_number";
      }
      this.canSpend = this.rawBalance >= this.rawSelectedAmount && this.rawAllowance >= this.rawSelectedAmount;
      this.canApprove = this.rawBalance >= this.rawSelectedAmount && this.rawAllowance < this.rawSelectedAmount;
      this.parseError = undefined;
    } catch (error) {
      this.parseError = error;
      this.canSpend = false;
      this.canApprove = false;
    }
    this.Validate();
  }

  async LoadDonateToInfo(recipientAccountId) {
    this.recipientData = new RecipientData(recipientAccountId);
    this.donateLimits = {};

    if (!this.firebase.initialized) return;
    if (!this.recipientData.accountId) {
      // invalid
      return;
    }

    const firestoreModule = await getFirebaseFirestore();
    const firestore = firestoreModule.getFirestore(this.firebase.app);
    const ref = firestoreModule.doc(firestore, `/user/${this.recipientData.accountId}/storage/public`);
    const snapshot = await firestoreModule.getDoc(ref);
    this.recipientData.valid = snapshot.exists();
    if (!this.recipientData.valid) {
      this.Validate();
      return;
    }

    const data = snapshot.data();
    this.recipientData.fromDBJson(data);

    this.donateLimits = {};
    const accountLimits = data?.limits || {};
    this.settings.tickerOrder.forEach((ticker) => {
      const systemMinValue = this.settings.limits[ticker] || 0;
      const accountMinValue = accountLimits[ticker] || 0;
      this.donateLimits[ticker] = Math.max(systemMinValue, accountMinValue);
    });

    this.Validate();
  }

  async Approve() {
    const wallet = this.sharedState.activeWallet;
    if (!(wallet instanceof WalletBase) || !wallet.chainId || !wallet.address) {
      return;
    }

    if (this.isPendingApprove) return;
    this.isPendingApprove = true;

    try {
      if (!this.donatrixAddress) throw "missing service contract address";
      if (this.rawSelectedAmount <= 0n) throw "invalid raw amount";
      if (this.selectedToken.address === wallet.zeroAddress) throw "programming error";
      if (!this.canApprove) throw "programming error";
    } catch (error) {
      this.isPendingApprove = false;
      throw error;
    }

    this.isPendingApprove = true;
    try {
      await wallet.SendApproveTx(this.selectedToken.address, this.donatrixAddress, this.rawSelectedAmount);
      this.UpdateBalanceAndAllowance();
    } catch (err) {
      if (!(err instanceof WalletUserRejectedError)) {
        console.log(err);
      }
    }
    this.isPendingApprove = false;
  }

  static convertFeeToUnits(feePercent) {
    // div by 100 then mult by MAX_FEE (10000n)
    const feeUnits = BigInt(Math.floor(feePercent * 100));
    return feeUnits;
  }

  async Donate(isTestDonate) {
    if (isTestDonate) {
      return this.ExplicitTestDonate(
        this.fromNicknameProp || "anonymous",
        this.messageProp,
        this.selectedToken.name,
        this.selectedAmountProp
      );
    }

    const wallet = this.sharedState.activeWallet;
    if (!(wallet instanceof WalletBase) || !wallet.chainId || !wallet.address) {
      return;
    }

    if (this.isPendingDonate) return;
    this.isPendingDonate = true;

    const feeOrServiceExists = this.donatrixAddress || this.feeAddress;
    const recipientAddress = this.recipientData.getWalletAddress(wallet);
    try {
      if (!this.firebase.account || this.firebase.readOnly) throw "invalid auth";
      if (!feeOrServiceExists) throw "missing service contract or fee address";
      if (this.rawSelectedAmount <= 0n) throw "invalid raw amount";
      if (!this.recipientData.valid) throw "invalid donate address";
      if (!recipientAddress) throw "invalid donate address";
      if (!this.canSpend) throw "programming error";
    } catch (error) {
      this.isPendingDonate = false;
      throw error;
    }

    const messageData = {
      message: this.messageProp,
    };
    if (this.customNickname) {
      messageData.nickname = this.fromNicknameProp || "anonymous";
    }

    try {
      const txHashCallback = (txHash) => {
        messageData["txHash"] = txHash;
        this.firebase.CallFunction("registerTransaction", messageData, 3);
      };

      const feeUnits = Donatrix.convertFeeToUnits(this.serviceFee);
      await wallet.SendDonateTx(
        recipientAddress,
        this.selectedToken.address,
        this.rawSelectedAmount,
        feeUnits,
        {
          splitterAddress: this.donatrixAddress,
          feeAddress: this.feeAddress,
        },
        txHashCallback
      );

      this.UpdateBalanceAndAllowance();
    } catch (err) {
      if (!(err instanceof WalletUserRejectedError)) {
        console.log(err);
      }
    }
    this.isPendingDonate = false;
  }

  Validate() {
    const calcError = () => {
      if (this.firebase.account === undefined) return DONATRIX_ERROR.InvalidAuth;
      if (this.firebase.account === this.recipientData.accountId) return DONATRIX_ERROR.SelfDonate;
      if (this.sharedState.activeWallet === undefined || !this.sharedState.activeWallet.isAttached)
        return DONATRIX_ERROR.NoActiveWallet;
      if (!this.recipientData.accountId || this.recipientData.valid === false)
        return DONATRIX_ERROR.InvalidRecipientAccount;

      const recipientAddress = this.recipientData.getWalletAddress(this.sharedState.activeWallet);
      if (recipientAddress === undefined) return DONATRIX_ERROR.RecipientNoAddress;
      if (Number(this.selectedAmountProp) === 0) return DONATRIX_ERROR.ZeroValue;
      if (this.parseError !== undefined) return DONATRIX_ERROR.InvalidAmount;
      if (this.rawSelectedAmount === 0n) return DONATRIX_ERROR.ZeroValue;
      if (this.rawSelectedAmount < this.rawLimit) return DONATRIX_ERROR.LowAmount;
      if (this.rawBalance < this.rawSelectedAmount) return DONATRIX_ERROR.LowBalance;
      if (this.rawSelectedAmount > this.rawAllowance) return DONATRIX_ERROR.LowAllowance;
      if (this.message.length > this.messageLimit) return DONATRIX_ERROR.TooLongMessage;
      return undefined;
    };
    this.donatrixError = calcError();
  }

  async ExplicitTestDonate(nickname, message, ticker, value) {
    if (this.isPendingDonate) return;
    if (!["string", "number"].includes(typeof value)) throw DONATRIX_ERROR.InvalidAmount;
    if (this.firebase.account === undefined || this.firebase.readOnly === true) throw DONATRIX_ERROR.InvalidAuth;
    if (message.length > this.messageLimit) return DONATRIX_ERROR.TooLongMessage;

    const { token, chain } = this.settings.findTokenByTicker(ticker);

    const ethers = await GetEthers();
    let rawValue = 0n;
    try {
      rawValue = ethers.utils.parseUnits(String(value), token.decimals);
    } catch (error) {
      throw DONATRIX_ERROR.InvalidAmount;
    }

    if (rawValue <= 0n) throw DONATRIX_ERROR.ZeroValue;

    const messageData = {
      chainId: chain.chainId,
      chainType: chain.chainType,
      currencyContract: token.address,
      message,
      rawValue: rawValue.toString(),
    };
    if (typeof nickname === "string") {
      messageData.nickname = nickname || "anonymous";
    }

    try {
      this.isPendingDonate = true;
      await this.firebase.CallFunction("registerTestTransaction", messageData);
      this.isPendingDonate = false;
    } catch (err) {
      this.isPendingDonate = false;
      console.error(err);
      throw DONATRIX_ERROR.RequestFailed;
    }
  }
}

export const utpItems = [
    {
        id: 'ac-1',
        click: 'chooseSafe',
        checked: true,
        title: 'Safe and secure',
        numberFirst: '876-01',
        numberSecond: '54',
        description: 'All funds are stored in your personal wallet Only you have access to thewallet. Byconnecting to our platform, you only log in and indicate where donations will besent. You willnot need any withdrawals, donations go directly to your authorized wallet.'
    },
    {
        id: 'ac-2',
        click: 'chooseAnon',
        checked: false,
        title: 'Anonymous and confidential', 
        numberFirst: '465-34',
        numberSecond: '34',
        description: 'You do not need any proof of identity to use our platform. your ID is the wallet.'
    },
    {
        id: 'ac-3',
        click: 'chooseAlerts',
        checked: false,
        title: 'Alerts and widgets',
        numberFirst: '685-12',
        numberSecond: '48',
        description: 'Already familiar notifications, donation statistics, adding media to messages, fundraising, etc., as well as our unique developments.'
    },
    {
        id: 'ac-4',
        click: 'chooseWeb',
        checked: false,
        title: 'Web 3 technologies',
        numberFirst: '326-17',
        numberSecond: '23',
        description: "Our platform is based on smart contracts and Web3 application. Personal account of every user is connected via their wallets.Funds come directly from donors to streamers, with automatic deduction of the service's commission."
    }
]
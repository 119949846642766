// Copyright 2023, Alexander Nekrasov, All rights reserved.
import { MessageSummaryItem } from "./MessageSummaryItem";
import { getInterval } from "../intervalLib";
export class MessageGraphCache {
    constructor() {
        this.rawData = {};
        this.period = "week";
        this.timezoneOffset = 0;
        this.items = [];
        this.loading = true;
    }
    static GetCacheLookupPeriod(period) {
        // we never have cache for "week", so using "month" for cache lookup
        return period === "week" ? "month" : period;
    }
    Reset() {
        // intentionally do not reset period and offset
        this.rawData = {};
        this.items = [];
        this.loading = true;
    }
    RebuildForDay() {
        this.period = "day";
        this.Rebuild();
    }
    RebuildForWeek() {
        this.period = "week";
        this.Rebuild();
    }
    RebuildForMonth() {
        this.period = "month";
        this.Rebuild();
    }
    RebuildForYear() {
        this.period = "year";
        this.Rebuild();
    }
    Rebuild() {
        const cachePeriod = MessageGraphCache.GetCacheLookupPeriod(this.period);
        this.timezoneOffset = this.rawData?.timezoneOffset || 0;
        const interval = getInterval(this.period, this.timezoneOffset);
        this.items = [];
        for (let i = 0; i < interval.dates.length - 1; ++i) {
            const startDate = interval.dates[i];
            const endDate = interval.dates[i + 1];
            const cacheName = this.period === "day" ? startDate.toISOString() : `${startDate.toISOString()}${this.timezoneOffset >= 0 ? "+" : ""}${this.timezoneOffset}`;
            const cacheRecord = (this.rawData[cachePeriod] || {})[cacheName];
            const item = new MessageSummaryItem(cacheName, startDate, endDate, cacheRecord);
            this.items.push(item);
        }
    }
    fromDBJson(data) {
        this.rawData = JSON.parse(JSON.stringify(data || {}));
        this.loading = false;
        this.Rebuild();
    }
}

<template>
    <ul class="media">
        <MediaLinksItem :linksItem="linksItem" v-for="linksItem in linksItems" :key="linksItem.id"/>
    </ul>
</template>
<script>
import { links } from '@/data/links.js';
import MediaLinksItem from '@/components/landing/mediaLinksItem.vue';

export default {
    components: { MediaLinksItem },

    computed: {
        linksItems() {
            return links
        }
    }
}
</script>
<style scoped lang="scss">
    .media {
        display: flex;
    }

    @media (min-width: 1800px){
        .media {
            justify-content: space-between;
        }
    }
</style>
// Copyright 2023, Alexander Nekrasov, All rights reserved.
import { AccountSettings } from "./AccountSettings";
export class RecipientData {
    constructor(accountId) {
        this.accountId = accountId;
        this.valid = undefined;
        this.accountSettings = new AccountSettings();
        this.nickname = "";
    }
    fromDBJson(data) {
        this.accountSettings.fromDBJson("public", data?.settings);
        this.nickname = data?.nickname || "anonymous";
        this.evmAddress = data?.evmAddress;
        this.solanaAddress = data?.solanaAddress;
    }
    getWalletAddress(wallet) {
        if (wallet?.type === "Ethereum")
            return this.evmAddress;
        if (wallet?.type === "Solana")
            return this.solanaAddress;
        return undefined;
    }
}

// Copyright 2023, Alexander Nekrasov, All rights reserved.
import { SettingBase } from "./SettingBase";
class FlagValue {
    constructor(setting, name, value) {
        this.setting = setting;
        this.name = name;
        this._value = value;
    }
    get value() {
        return this._value;
    }
    set value(value) {
        if (value)
            this.setting.SetFlag(this.name);
        else
            this.setting.ResetFlag(this.name);
    }
}
export class SettingFlags extends SettingBase {
    constructor(domain, name, options) {
        if (options.length <= 0)
            throw "no valid options";
        super(domain, name, "SettingFlags");
        this.options = options;
        this.actualValue = new Set();
        this.pendingValue = undefined;
        this.numFlags = 0;
    }
    get dirty() {
        return this.pendingValue !== undefined;
    }
    get error() {
        return this.numFlags <= 0 ? "cant_be_empty" : undefined;
    }
    get value() {
        return this.options.map((option) => {
            const enabled = this.pendingValue !== undefined ? this.pendingValue.has(option) : this.actualValue.has(option);
            return new FlagValue(this, option, enabled);
        });
    }
    get default() {
        return this.options.map((option) => new FlagValue(this, option, false));
    }
    get flags() {
        return this.value.reduce((list, flag) => {
            if (flag.value)
                list.push(flag.name);
            return list;
        }, []);
    }
    fromDBJson(data) {
        this.Reset(data[this.name] || []);
        this.MarkInitialized();
    }
    toDBJson(data, force) {
        const produceData = force === true || this.dirty;
        if (produceData) {
            // const flags: string[] = [];
            // for (let flag of this.value) {
            //   if (flag.value) flags.push(flag.name);
            // }
            data[this.name] = this.flags;
        }
    }
    Commit() {
        if (this.pendingValue !== undefined) {
            this.actualValue = this.pendingValue;
            this.pendingValue = undefined;
        }
    }
    Revert() {
        this.pendingValue = undefined;
    }
    Reset(flags) {
        this.pendingValue = undefined;
        this.actualValue = new Set();
        for (let flag of flags) {
            if (!this.options.includes(flag))
                continue;
            this.actualValue.add(flag);
        }
        this.numFlags = this.value.reduce((prev, flag) => {
            return prev + (flag.value ? 1 : 0);
        }, 0);
    }
    SetFlag(flag) {
        if (!this.options.includes(flag))
            return;
        if (this.pendingValue === undefined) {
            this.pendingValue = new Set(this.actualValue);
        }
        this.pendingValue.add(flag);
        if (eqSet(this.actualValue, this.pendingValue)) {
            this.pendingValue = undefined;
        }
        this.numFlags = this.value.reduce((prev, flag) => {
            return prev + (flag.value ? 1 : 0);
        }, 0);
    }
    ResetFlag(flag) {
        if (!this.options.includes(flag))
            return;
        if (this.pendingValue === undefined) {
            this.pendingValue = new Set(this.actualValue);
        }
        this.pendingValue.delete(flag);
        if (eqSet(this.actualValue, this.pendingValue)) {
            this.pendingValue = undefined;
        }
        this.numFlags = this.value.reduce((prev, flag) => {
            return prev + (flag.value ? 1 : 0);
        }, 0);
    }
}
export function eqSet(a, b) {
    if (a.size === b.size) {
        for (let k of a) {
            if (!b.has(k))
                return false;
        }
        return true;
    }
    return false;
}

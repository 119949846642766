// Copyright 2023, Alexander Nekrasov, All rights reserved.
import { BlockchainSettings } from "./classes/BlockchainSettings";
import { NicknameCache } from "./classes/NicknameCache";
function unbound() {
    console.log("unbound");
    return Promise.reject("unbound");
}
class SharedState {
    constructor() {
        this.bootupAt = new Date();
        this.settings = new BlockchainSettings();
        this.nicknameCache = new NicknameCache();
        this.accountId = undefined;
        this.accountEvmAddress = undefined;
        this.accountSolanaAddress = undefined;
        this.attachWalletDelegate = unbound;
        this.detachWalletDelegate = unbound;
        this.signInDelegate = unbound;
        this.signingInWithUuid = undefined;
        this.httpCallableDelegate = unbound;
        this.getSolanaLatestBlockhash = unbound;
        this.activeWallet = undefined;
        this.publicInitialized = false;
        this.numWallets = 0;
    }
}
export const sharedState = new SharedState();

import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from '@/pages/MainPage'
import aboutSection from '@/pages/MainPage'
import acContainer from '@/pages/MainPage'
import accordionAnchor from '@/pages/MainPage'
import blockchain from '@/pages/MainPage'
import swiper from '@/pages/MainPage'
import i18n from '../i18n';


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "main",
    component: MainPage,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import ('@/pages/DashboardPage'),
    meta: {
      breadcrumb: i18n.t('breadcrumbs.dashboard'),
    },
    children: [
      {
        path: "/messages",
        name: "messages",
        component: () => import ('@/components/lastmessages/lastmessages.vue'),
        meta: {
          breadcrumb: i18n.t('breadcrumbs.messages'),
        },
      },
      {
        path: "/account",
        name: "account",
        component: () => import ('@/components/account/account.vue'),
        meta: {
          breadcrumb: i18n.t('breadcrumbs.control'),
        },
      },
      {
        path: "/donationSettings",
        name: "donationSettings",
        component: () => import ('@/components/donationSettings/donationSettings.vue'),
        meta: {
          breadcrumb: i18n.t('breadcrumbs.donationSettings'),
        },
      },
      {
        path: "/donation",
        name: "donation",
        component: () => import ('@/components/donationPage/donationPage.vue'),
        meta: {
          breadcrumb: i18n.t('breadcrumbs.donationsPage'),
        },
      },
      {
        path: "/widgets",
        name: "widgets",
        component: () => import ('@/components/widgets/widgets.vue'),
        meta: {
          breadcrumb: i18n.t('breadcrumbs.widgets'),
        },
      }
    ]
  },
  {
    path: '/r',
    name: "addDonation",
    component: () => import ('@/pages/DonationPage'),
  },
  {
    path: '/view',
    name: "launch",
    component: () => import ('@/components/widgets/notification.vue'),
  },
  {
    path: '/messagesList',
    name: "launch",
    component: () => import ('@/components/dashboard/messagesWidget.vue'),
  },
  {
    path: '/about',
    name: 'aboutSection',
    component: aboutSection
  },
  {
    path: '/ac-container',
    name: 'ac-container',
    component: acContainer
  },
  {
    path: '/accordion-anchor',
    name: 'accordion-anchor',
    component: accordionAnchor
  },
  {
    path: '/blockchain',
    name: 'blockchain',
    component: blockchain
  },
  {
    path: '/swiper',
    name: 'swiper',
    component: swiper
  },
  {
    path: "*",
    name: "notFound",
    component: () => import ('@/pages/NotFoundPage'),
  },
  {
    path: "/testbed",
    name: "Testbed",
    component: () => import(/* webpackChunkName: "testbed" */ "@/pages/TestbedPage.vue"),
  },
];


const router = new VueRouter({
  mode: 'history',
  routes,
})



export default router;

// Copyright 2023, Alexander Nekrasov, All rights reserved.
import { MetaSetting } from "./SettingMeta";
import { DOMAIN, NotificationVariationBase } from "./NotificationVariationBase";
import { SettingBool } from "./SettingBool";
import { SettingChoice } from "./SettingChoice";
import { SettingFlags } from "./SettingFlags";
import { SettingNumber } from "./SettingNumber";
import { SettingString } from "./SettingString";
export class NotificationVariationFiat extends NotificationVariationBase {
    constructor(uuid, createdAt, manager) {
        super(uuid, NotificationVariationFiat.Type, createdAt, manager);
        this.groupIndexSetting = new SettingChoice(DOMAIN, "groupIndex", [1, 2, 3, 4, 5, 6]);
        this.displayNameSetting = new SettingString(DOMAIN, "displayName", "");
        this.enabledSetting = new SettingBool(DOMAIN, "enabled", true);
        this.fiatMinValue = new SettingNumber(DOMAIN, "fiatMinValue", 0);
        this.fiat = new SettingChoice(DOMAIN, "fiat", ["usd", "eur", "rub"]);
        this.allowAnyCrypto = new SettingBool(DOMAIN, "allowAnyCrypto", true);
        this.allowedCryptoTickers = MetaSetting(this.allowAnyCrypto, new SettingFlags(DOMAIN, "allowedCryptoTickers", this.manager.settings.tickerOrderChecked), { disable: true, hide: false });
    }
    get enabled() {
        return this.enabledSetting.value;
    }
    get displayName() {
        return this.displayNameSetting.value;
    }
    get groupIndex() {
        return Number(this.groupIndexSetting.value);
    }
    get allSettings() {
        return [
            this.groupIndexSetting,
            this.displayNameSetting,
            this.enabledSetting,
            this.alertDurationSecondsSetting,
            this.textDelaySecondsSetting,
            this.textDurationSecondsSetting,
            this.chromaColorSetting,
            this.fiatMinValue,
            this.fiat,
            this.allowAnyCrypto,
            this.allowedCryptoTickers,
        ];
    }
    filter(message) {
        if (!this.enabled)
            return false;
        const fiatValue = message?.fiatValue || {};
        const valueOk = fiatValue[this.fiat.value] >= this.fiatMinValue.value;
        if (!valueOk)
            return false;
        const tokenOk = this.allowAnyCrypto.value ? true : this.allowedCryptoTickers.flags.includes(message.ticker);
        if (!tokenOk)
            return false;
        return true;
    }
    variationFactor(message) {
        // just return minValue in usd, use fiat factor from message
        const fiatValue = message?.fiatValue || {};
        const fiatFactor = fiatValue[this.fiat.value] / fiatValue.usd;
        return this.fiatMinValue.value / fiatFactor;
    }
}
NotificationVariationFiat.Type = "fiat";

// Copyright 2023, Alexander Nekrasov, All rights reserved.
export class NotificationVariationRuntime {
    constructor(source) {
        this.uuid = source.uuid;
        this.displayName = source.displayName;
        this.alertDurationSeconds = source.alertDurationSeconds;
        this.textDelaySeconds = source.textDelaySeconds;
        this.textDurationSeconds = source.textDurationSeconds;
        this.chromaColor = source.chromaColor;
    }
}

export const networks = [
    {
        id:1,
        name: 'Ethereum'
    },
    {
        id:2,
        name: 'Polygon'
    },
    {
        id:3,
        name: 'BNB Smart Chain'
    },
    {
        id:4,
        name: 'Solana'
    },
    {
        id:5,
        name: 'Tron'
    },
]
// Copyright 2023, Alexander Nekrasov, All rights reserved.

import { Firebase } from "./firebase";
import { getFirebaseFirestore } from "./firebaseChunks";
import { sharedState } from "./sharedState";
import { LimitsHelper } from "./classes/LimitsHelper";
import { Message } from "./classes/Message";
import { AccountSettings } from "./classes/AccountSettings";
import { NotificationVariationBase } from "./classes/NotificationVariationBase";
import { NotificationVariationManager } from "./classes/NotificationVariationManager";
import { MessageGraphCache } from "./classes/MessageGraphCache";
import { Donatrix } from "./donatrix";

// better not to add more pages here, but handle specific pages with dedicated classes
// see Handle... functions
const ALLOWED_PAGES = Object.freeze(["account", "public"]);

export class Account {
  constructor(firebase, donatrix) {
    if (!(firebase instanceof Firebase)) throw "InjectError";
    if (!(donatrix instanceof Donatrix)) throw "InjectError";

    this.limits = new LimitsHelper();
    this.firebase = firebase;
    this.donatrix = donatrix;
    this.initialized = false;
    this.cancelSubscription = undefined;
    this.generatingToken = false;
    this.sharedState = sharedState;

    this.messageGraph = new MessageGraphCache();
    this.variationManager = new NotificationVariationManager(this.donatrix.settings);

    this.Reset();
  }

  get disableTokenGenerator() {
    return this.firebase.readOnly === true;
  }

  Reset() {
    for (let page of ALLOWED_PAGES) {
      this[page] = {};
    }
    this.sharedState.publicInitialized = false;
    this.sharedState.numWallets = 0;
    this.sharedState.accountEvmAddress = undefined;
    this.sharedState.accountSolanaAddress = undefined;

    this.accountSettings = new AccountSettings();
    this.accountSettings.saveDelegate = this.SaveAccountSettings.bind(this);

    this.messageGraph.Reset();

    this.variationManager.Reset();
    this.variationManager.listenDelegate = this.ListenVariations.bind(this);
    this.variationManager.saveDelegate = this.SaveVariation.bind(this);
    this.variationManager.deleteDelegate = this.DeleteVariation.bind(this);
    this.variationManager.testDelegate = this.TestVariation.bind(this);

    this.donatrix.Validate();
  }

  FirebaseAccount(account) {
    if (!this.initialized && account) {
      console.log("initializing account");
      this.Init();
      this.UpdateSettings();
    } else if (this.initialized && account === undefined) {
      console.log("deinitializing account");
      this.Cleanup();
    }
  }

  UpdateSettings() {
    if (!this.firebase.account || this.firebase.readOnly || !this.donatrix.settingsReady) return;

    const settings = this.donatrix.settings;
    this.limits.UpdateSettings(settings);
  }

  async Init() {
    this.Reset();
    this.initialized = true;
    this.StartListenUserSettings();
  }

  Cleanup() {
    this.initialized = false;
    if (typeof this.cancelSubscription === "function") {
      this.cancelSubscription();
      this.cancelSubscription = undefined;
    }
    this.limits.Reset();
    this.Reset();
  }

  async GenerateAccessToken() {
    if (this.sharedState.accountId === undefined) return;
    if (this.generatingToken) return;
    this.generatingToken = true;
    //console.log("account::generateAccessToken");

    try {
      await this.firebase.CallFunction("generateAccessToken");
      this.generatingToken = false;
    } catch (error) {
      this.generatingToken = false;
      throw error;
    }
  }

  HandleAccountSettings(page, settings) {
    if (!["public", "account"].includes(page)) return;
    this.accountSettings.fromDBJson(page, settings || {});
  }

  HandleSharedState(page, data) {
    if (page === "public") {
      this.sharedState.accountEvmAddress = data?.evmAddress;
      this.sharedState.accountSolanaAddress = data?.solanaAddress;

      const fields = ["accountEvmAddress", "accountSolanaAddress"];
      this.sharedState.numWallets = fields.reduce(
        (number, field) => (this.sharedState[field] === undefined ? number : 1 + number),
        0
      );

      this.sharedState.publicInitialized = true;

      if (!this.firebase.readOnly) {
        this.limits.UpdateCurrentLimits(data?.limits || {});
        this.donatrix.Validate();
      }
    }
  }

  HandleGraphCache(page, data) {
    if (page !== "cache") return;
    if (this.firebase.readOnly) return; // skip processing if RO auth
    this.messageGraph.fromDBJson(data);
  }

  HandleVariationTest(page, data) {
    if (page !== "variationTest") return;
    if (data.replayAt < this.sharedState.bootupAt.toISOString()) return;

    const variation = this.variationManager.GetVariation(data?.variationId);
    if (!variation) return;

    const message = new Message("test", this.accountSettings);
    message.nickname = "system";
    message.textProperty = `variation "${variation.displayName}" test message`;

    this.variationManager.ProcessMessage(message, variation.uuid);
  }

  async StartListenUserSettings() {
    const firestoreModule = await getFirebaseFirestore();
    const firestore = firestoreModule.getFirestore(this.firebase.app);
    const account = this.firebase.account;
    const storageCollection = firestoreModule.collection(firestore, `/user/${account}/storage`);
    this.cancelSubscription = firestoreModule.onSnapshot(storageCollection, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (["added", "modified"].includes(change.type)) {
          const id = change.doc.id;
          const data = change.doc.data();
          if (ALLOWED_PAGES.includes(change.doc.id)) {
            this[id] = data;
          }
          this.HandleSharedState(id, data);
          this.HandleAccountSettings(id, data.settings);
          this.HandleGraphCache(id, data);
          this.HandleVariationTest(id, data);
        }
      });
    });
  }

  // this function must match on front- and back- ends to have consistent behavior
  filterNickname(nickname) {
    if (typeof nickname !== "string") throw "empty string";
    //const out = nickname.replace(/[ `~!@#$%^&*()_|+\-=?;:'",.<>{}[\]/]/gi, "");
    const out = nickname.replace(/[^a-zA-Z0-9]/gi, "").substring(0, 24);
    if (out.length < 3) throw "too short";
    return out;
  }

  async testNicknameIsUnique(newNickname, filteredCallback) {
    if (newNickname === this.public.nickname) throw "same";

    const filteredValue = this.filterNickname(newNickname);
    if (typeof filteredCallback === "function") filteredCallback(filteredValue);

    const firestoreModule = await getFirebaseFirestore();
    const firestore = firestoreModule.getFirestore(this.firebase.app);
    const names = firestoreModule.collection(firestore, "/names");
    // prettier-ignore
    const query = firestoreModule.query(names
      , firestoreModule.where("nickname", "==", filteredValue.toLowerCase())
      , firestoreModule.limit(1)
    );
    const snapshot = await firestoreModule.getDocs(query);
    if (snapshot.size > 0) {
      const owner = snapshot.docs[0].data().accountId;
      if (owner === this.firebase.account) return; // all right
      throw "not unique";
    }
  }

  async changeNickname(newNickname) {
    const nickname = this.filterNickname(newNickname);
    await this.firebase.CallFunction("changeNickname", { nickname });
  }

  async SaveAccountSettings(accountSettings) {
    if (!(accountSettings instanceof AccountSettings)) throw `AccountSettings expected got ${accountSettings}`;

    const accountId = this.firebase.account;
    if (!accountId || this.firebase.readOnly) return; // nothing to do;

    const firestoreModule = await getFirebaseFirestore();
    const firestore = firestoreModule.getFirestore(this.firebase.app);
    const batch = firestoreModule.writeBatch(firestore);
    let commit = false;
    for (let domain of accountSettings.domains) {
      if (!domain) continue;

      const settings = accountSettings.toDBJson(domain);
      const num = Object.keys(settings).length;
      if (num === 0) continue; // nothing to do;

      const ref = firestoreModule.doc(firestore, `/user/${accountId}/storage/${domain}`);
      batch.set(ref, { settings }, { merge: true });
      commit = true;
    }

    if (commit) {
      await batch.commit();
    }
  }

  async ListenVariations(manager) {
    if (!(manager instanceof NotificationVariationManager)) throw "internal";

    const urlParams = new URLSearchParams(window.location.search);
    const groupIndex = Number(urlParams.get("groupIndex")) || 0;

    const firestoreModule = await getFirebaseFirestore();
    const firestore = firestoreModule.getFirestore(this.firebase.app);
    const variations = firestoreModule.collection(firestore, `/user/${this.firebase.account}/variations`);

    let query = variations;
    if (this.firebase.readOnly) {
      // only have to filter when not in dashboard
      query = firestoreModule.query(query, firestoreModule.where("enabled", "==", true));
    }
    query = firestoreModule.query(query, firestoreModule.limit(NotificationVariationManager.MAX_VARIATIONS));

    return firestoreModule.onSnapshot(query, (snapshot) => {
      manager.MarkLoaded(groupIndex);
      snapshot.docChanges().forEach((change) => {
        const action = change.type;
        const uuid = change.doc.id;
        const data = change.doc.data();
        manager.ClientUpdateVariation(action, uuid, data);
      });
      manager.VerifyHasDefault();
    });
  }

  async SaveVariation(variation) {
    if (!this.firebase.account || this.firebase.readOnly) throw "unauthorized";
    if (!(variation instanceof NotificationVariationBase)) throw "internal";

    const firestoreModule = await getFirebaseFirestore();
    const firestore = firestoreModule.getFirestore(this.firebase.app);
    const ref = firestoreModule.doc(firestore, `/user/${this.firebase.account}/variations/${variation.uuid}`);
    const dbValue = {};
    variation.toDBJson(dbValue);
    if (Object.keys(dbValue).length === 0) return;
    await firestoreModule.setDoc(ref, dbValue, { merge: true });
  }

  async DeleteVariation(variation) {
    if (!this.firebase.account || this.firebase.readOnly) throw "unauthorized";
    if (!(variation instanceof NotificationVariationBase)) throw "internal";

    const firestoreModule = await getFirebaseFirestore();
    const firestore = firestoreModule.getFirestore(this.firebase.app);
    const ref = firestoreModule.doc(firestore, `/user/${this.firebase.account}/variations/${variation.uuid}`);
    await firestoreModule.deleteDoc(ref);
  }

  async TestVariation(variation) {
    if (!this.firebase.account || this.firebase.readOnly) throw "unauthorized";
    if (!(variation instanceof NotificationVariationBase)) throw "internal";

    const firestoreModule = await getFirebaseFirestore();
    const firestore = firestoreModule.getFirestore(this.firebase.app);
    const ref = firestoreModule.doc(firestore, `/user/${this.firebase.account}/storage/variationTest`);
    const value = {
      variationId: variation.uuid,
      replayAt: new Date().toISOString(),
    };
    await firestoreModule.runTransaction(firestore, async (transaction) => {
      transaction.set(ref, value);
    });
    //await firestoreModule.setDoc(ref, value);
  }
}

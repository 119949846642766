<template>
  <section id="about">
    <div class="container">
      <span class="about-description">
        <span class="about-description__text">
          <span class="about-description__main">Donatrix</span>
          {{ $t('about.description') }}
        </span>
      </span>

      <div class="about-title">
        <span class="about-title__icon"></span>
        <h2 class="about-title__text">
          {{ $t('about.title') }}
        </h2>
      </div>

      <AboutList />
    </div>
  </section>
</template>
<script>
import AboutList from '@/components/landing/aboutList'
export default {
  components: { AboutList }
}
</script>

<style scoped lang="scss">
#about {
  margin-top: -4px;
  padding-top: 24px;
  padding-bottom: 200px;
  background: #07041B;
  overflow: hidden;
}

#about .about-description {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

#about .about-description__main {
  color: #FFF;
  text-align: center;
  font-family: ZenDots;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-transform: uppercase;
  margin-right: 1px;
}

#about .about-description__text {
  color: #FFF;
  font-family: Gilroy-Regular;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

#about .about-title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  margin-right: 51px;
}

#about .about-title__icon {
  width: 33px;
  height: 34px;
  margin-right: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='33' height='34' viewBox='0 0 33 34' fill='none'%3E%3Cpath d='M18.7713 13.4493L8.94585 3.25511C7.77196 2.03716 5.82377 2.03005 4.64103 3.2394L2.55526 5.3721C1.41497 6.53804 1.41497 8.40135 2.55526 9.56729L12.6482 19.8873C14.5057 21.7866 13.16 24.9848 10.5034 24.9848H3C1.34315 24.9848 0 26.328 0 27.9848V31C0 32.6569 1.34315 34 3 34H27.2536C28.0311 34 28.7782 33.6981 29.3375 33.158L32.084 30.5058C32.6694 29.9405 33 29.1616 33 28.3478V25.4353C33 24.6512 32.693 23.8982 32.1448 23.3377L26.5174 17.5837C25.3649 16.4053 25.379 14.5178 26.549 13.3567L32.1132 7.83459C32.6808 7.27134 33 6.50482 33 5.70522V3C33 1.34315 31.6569 0 30 0H26.9313C25.2744 0 23.9313 1.34314 23.9313 3V11.3674C23.9313 14.0669 20.6446 15.3929 18.7713 13.4493Z' fill='white'/%3E%3C/svg%3E");
}

#about .about-title__text {
  margin: 0;
  padding-top: 13px;
  color: #FFF;
  text-align: center;
  font-family: Gilroy-Medium;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 15px */
}


@media (min-width: 1800px) {
  #about {
    padding-top: 57px;
  }

  #about .about-description__main {
    font-size: 18px;
  }

  #about .about-description__text {
    font-size: 18px;
  }

  #about .about-title {
    margin-right: 41px;
  }

  #about .about-title__text {
    font-size: 18px;
  }
}

@media (min-width: 0px) and (max-width: 1290px) {
  #about {
    padding-top: 77px;
    padding-bottom: 100px;
  }

  #about .about-description {
    margin-bottom: 24px;
    margin-right: 12px;
  }

  #about .about-description__main {
    font-size: 12px;
  }

  #about .about-description__text {
    font-size: 12px;
  }

  #about .about-title {
    margin-right: 5px;
  }

  #about .about-title__icon {
    width: 21px;
    height: 22px;
    margin-right: 11px;
  }

  #about .about-title__text {
    padding-top: 11px;
    font-size: 12px;
  }

}

@media (max-width: 680px) {
  #about {
    padding-top: 59px;
  }

  #about .about-description {
    margin-right: 0px;
    margin-bottom: 17px;
  }

  #about .about-description__main {
    margin-right: 0;
  }

  #about .about-description__text {
    text-align: center;
    max-width: 229px;
  }

  #about .about-title {
    margin-right: 0px;
    margin-left: 6px;
  }

  .about-list__item-cart .cart-icon {
    top: 16px;
  }
}</style>


// Copyright 2023, Alexander Nekrasov, All rights reserved.

export function serverTimestamp(unixTimestamp) {
  const date = unixTimestamp === undefined ? new Date() : new Date(Number(unixTimestamp) || 0);
  return date.toISOString();
}

export function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export default {};

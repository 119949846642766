import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({

    state: {
        logIn: 'none',
        userIsLoged: false,
        newFiat: localStorage.getItem('fiat') || 'USD',
        newCalendarFormat: localStorage.getItem('calendarFormat') || 'Auto',
    },

    mutations: {
        openLogin(state) {
          state.logIn = 'flex'
        },

        closeLogin(state) {
            state.logIn = 'none'
        },

        newFiat(state) {
            state.newFiat = localStorage.getItem('fiat') || ''
        },

        newCalendarFormat(state) {
            state.newCalendarFormat = localStorage.getItem('calendarFormat') || ''
        },

    }
})
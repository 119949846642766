// Copyright 2023, Alexander Nekrasov, All rights reserved.
function _key(prefix, key) {
    return `${prefix}::${key}`;
}
export class LocalStorage {
    constructor(prefix) {
        this.prefix = prefix;
    }
    set(key, value) {
        const value_json = JSON.stringify(value);
        localStorage.setItem(_key(this.prefix, key), value_json);
    }
    get(key, defvalue) {
        try {
            const value_json = localStorage.getItem(_key(this.prefix, key)) || "null";
            return JSON.parse(value_json) || defvalue;
        }
        catch (e) {
            return defvalue;
        }
    }
    delete(key) {
        localStorage.removeItem(_key(this.prefix, key));
    }
}

// Copyright 2023, Alexander Nekrasov, All rights reserved.
import { NotificationVariationBase } from "./NotificationVariationBase";
import { NotificationVariationFiat } from "./NotificationVariationFiat";
export class NotificationVariationManager {
    constructor(settings) {
        if (!settings)
            throw "InjectError";
        this.settings = settings;
        this.variations = [];
        this.isLoading = false;
        this.isLoaded = false;
        this.groupIndex = 0;
    }
    get canCreate() {
        return this.variations.length < NotificationVariationManager.MAX_VARIATIONS;
    }
    RegisterDisplay(displayFunc) {
        this.displayDelegate = displayFunc;
        console.log("display delegate registered");
    }
    UnregisterDisplay() {
        this.displayDelegate = undefined;
        console.log("display delegate unregistered");
    }
    Reset() {
        if (this.unsub) {
            this.unsub();
            this.unsub = undefined;
        }
        this.variations = [];
        this.isLoading = false;
        this.isLoaded = false;
    }
    MarkLoaded(groupIndex) {
        this.isLoading = false;
        this.isLoaded = true;
        this.groupIndex = groupIndex;
    }
    VerifyHasDefault() {
        if (this.groupIndex <= 1) {
            const variation = this.variations.find((variation) => variation.type === NotificationVariationBase.Type);
            if (!variation) {
                this.CreateVariation("default");
            }
        }
    }
    async LoadVariations() {
        if (this.unsub !== undefined)
            return;
        if (this.listenDelegate === undefined)
            throw "unbound";
        if (this.isLoading)
            return;
        this.isLoading = true;
        this.unsub = await this.listenDelegate(this);
    }
    CreateVariation(type, uuid, createdAt) {
        if (!this.isLoaded)
            throw "cant_create_not_initialized";
        if (!this.canCreate)
            throw "cant_create_more_variations";
        if (!type || !type.length)
            return undefined;
        if (type === NotificationVariationBase.Type)
            return this.CreateDefaultVariation();
        else if (type === NotificationVariationFiat.Type)
            return this.CreateFiatVariation(uuid || window.crypto.randomUUID(), createdAt || new Date());
        else {
            console.error(`can't create variation of unexpected type '${type}'`);
        }
    }
    CloneVariation(uuid) {
        const variation = this.variations.find((variation) => variation.uuid === uuid);
        if (!variation)
            throw "source variation not exists";
        const dbData = {};
        variation.toDBJson(dbData);
        const newVariation = this.CreateVariation(dbData.type);
        return newVariation;
    }
    CreateDefaultVariation() {
        const newVariation = new NotificationVariationBase("default", "default", new Date(0), this);
        newVariation.MarkInitialized();
        newVariation.isCommitted = true;
        this.variations.unshift(newVariation);
        return newVariation;
    }
    CreateFiatVariation(uuid, createdAt) {
        const newVariation = new NotificationVariationFiat(uuid, createdAt, this);
        newVariation.MarkInitialized();
        this.variations.push(newVariation);
        this.variations.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
        return newVariation;
    }
    SaveVariation(variation) {
        return this.saveDelegate ? this.saveDelegate(variation) : Promise.reject("unbound");
    }
    DeleteVariation(variation) {
        return this.deleteDelegate ? this.deleteDelegate(variation) : Promise.reject("unbound");
    }
    async TestVariation(variation) {
        if (variation.dirty) {
            await this.SaveVariation(variation);
        }
        return this.testDelegate ? this.testDelegate(variation) : Promise.reject("unbound");
    }
    ClientDeleteVariation(uuid) {
        this.variations = this.variations.filter((variation) => variation.uuid !== uuid);
    }
    ClientUpdateVariation(action, uuid, data) {
        if (action === "removed") {
            this.ClientDeleteVariation(uuid);
        }
        else {
            const variation = this.variations.find((variation) => variation.uuid === uuid)
                || this.CreateVariation(data?.type, data?.uuid, new Date(data?.createdAt || new Date()));
            variation?.fromDBJson(data);
        }
    }
    GetVariation(variationId) {
        return this.variations.find((variation) => variation.uuid === variationId);
    }
    ProcessMessage(message, variationId) {
        if (!this.displayDelegate)
            return;
        let variation = undefined;
        if (variationId) {
            variation = this.variations.find((variation) => variation.uuid === variationId && this.groupIndex === variation.groupIndex);
            if (!variation) {
                // drop silently, it is not for us
                return;
            }
        }
        if (variation === undefined) {
            const variations = this.variations.filter((variation) => variation.filter(message));
            variations.sort((a, b) => b.variationFactor(message) - a.variationFactor(message));
            variation = variations[0];
            //console.log(variations.map((v) => v.uuid));
        }
        if (variation && variation.groupIndex === this.groupIndex) {
            message.UpdateNickname().then(() => {
                if (this.displayDelegate && variation)
                    this.displayDelegate(message, variation.runtime);
            });
        }
    }
}
NotificationVariationManager.MAX_VARIATIONS = 50; // !!! also in rules !!!

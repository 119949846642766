// Copyright 2023, Alexander Nekrasov, All rights reserved.
export class TokenSettings {
    constructor(data) {
        this.name = data?.name || "";
        this.address = data?.address || "";
        this.decimals = data?.decimals || 0;
        this.coingecko = data?.coingecko || "";
    }
}
export class ChainSettings {
    constructor(data) {
        this.blockchain = data?.blockchain || "";
        this.chainId = data?.chainId || "";
        this.isTestnet = data?.isTestnet || true;
        this.blockExplorerUrlTemplate = data?.blockExplorerUrlTemplate || "";
        this.donatrixAddress = data?.donatrixAddress || "";
        this.feeAddress = data?.feeAddress || "";
        this.supportedTokens = (data?.supportedTokens || []).map((data) => {
            return new TokenSettings(data);
        });
        this.icon = data?.icon;
    }
    findTokenByTicker(ticker) {
        for (let token of this.supportedTokens) {
            if (token.name === ticker)
                return token;
        }
        return undefined;
    }
    findToken(address) {
        if (!address)
            return undefined;
        address = address.toLowerCase();
        for (let token of this.supportedTokens) {
            if (token.address.toLowerCase() === address)
                return token;
        }
        return undefined;
    }
}
export class BlockchainSettings {
    constructor() {
        this.messageLimit = 1000;
        this.chains = [];
        this.solanaChains = [];
        this.initialized = false;
        this.renameDelaySeconds = 60;
        this.serviceFeePercent = undefined;
        this.messageLimit = 1000;
        this.tickerOrder = [];
        this.limits = {};
    }
    get tickerOrderChecked() {
        if (!this.initialized)
            throw "attemp to access tickerOrder before initialized";
        return this.tickerOrder;
    }
    fromJson(data) {
        const chains = data.chains || [];
        this.chains = chains.map((chainData) => {
            return new ChainSettings(chainData);
        });
        const solanaChains = data.solanaChains || [];
        this.solanaChains = solanaChains.map((chainData) => {
            return new ChainSettings(chainData);
        });
        this.initialized = true;
        this.renameDelaySeconds = data?.renameDelaySeconds || 60;
        this.serviceFeePercent = data?.serviceFeePercent || 10;
        this.messageLimit = data?.message?.limit || 1000;
        this.tickerOrder = data?.tickerOrder || [];
        this.limits = data?.limits || {};
    }
    findChain(chainType, chainId) {
        if (chainType === "evm") {
            return this.chains.find((chain) => chain.chainId === chainId);
        }
        else if (chainType === "solana") {
            return this.solanaChains.find((chain) => chain.chainId === chainId);
        }
        else {
            return undefined;
        }
    }
    // DISCLAIMER: this method will find first matching token by ticker,
    // take into account we may have multiple tokens in different networks
    // this method is only expected to be used for test donations
    findTokenByTicker(ticker) {
        for (let chain of this.chains) {
            const token = chain.findTokenByTicker(ticker);
            if (token)
                return { token, chain };
        }
        for (let chain of this.solanaChains) {
            const token = chain.findTokenByTicker(ticker);
            if (token)
                return { token, chain };
        }
        throw `failed to find "${ticker}" token info`;
    }
}

// Copyright 2023, Alexander Nekrasov, All rights reserved.
import { SettingBase } from "./SettingBase";
import { clamp } from "../mathLib";
export class SettingSlider extends SettingBase {
    constructor(domain, name, defaultValue, minValue, maxValue, step) {
        if (maxValue < minValue)
            throw "maxValue can't be less minValue";
        super(domain, name, "SettingSlider");
        this.defaultValue = clamp(defaultValue, minValue, maxValue);
        this.minValue = minValue;
        this.maxValue = maxValue;
        this.step = step;
        this.actualValue = defaultValue;
        this.pendingValue = undefined;
    }
    get dirty() {
        return this.pendingValue !== undefined;
    }
    get value() {
        return this.dirty && this.pendingValue !== undefined ? this.pendingValue : this.actualValue;
    }
    fromDBJson(data) {
        this.Reset(data[this.name]);
        this.MarkInitialized();
    }
    toDBJson(data, force) {
        if (force === true) {
            data[this.name] = this.value;
        }
        else if (this.pendingValue !== undefined) {
            data[this.name] = this.pendingValue;
        }
    }
    Commit() {
        if (this.pendingValue !== undefined) {
            this.actualValue = this.pendingValue;
            this.pendingValue = undefined;
        }
    }
    Revert() {
        this.pendingValue = undefined;
    }
    Reset(value) {
        this.actualValue = value === undefined ? this.defaultValue : clamp(value, this.minValue, this.maxValue);
        this.pendingValue = undefined;
    }
    SetValue(value) {
        value = Number(value);
        const isActual = value === this.actualValue;
        this.pendingValue = isActual ? undefined : clamp(value, this.minValue, this.maxValue);
    }
}

// Copyright 2023, Alexander Nekrasov, All rights reserved.
import { getFirebaseFirestore } from "../firebaseChunks";
const BATCH_SIZE = 30; // 30 is maximum for firestore "in" query
// eslint-disable-next-line no-unused-vars
function debug(...args) {
    //console.log(...args);
}
export class NicknameCache {
    constructor() {
        this.promises = {};
        this.cached = {};
        this.queue = [];
        this.isFetching = false;
    }
    resolveNickname(accountId, nickname) {
        if (process.env.node_env === 'debug') {
            debug(`resolved ${accountId} -> ${nickname}`);
        }
        this.cached[accountId] = nickname;
        const array = this.promises[accountId] || [];
        delete this.promises[accountId];
        array.forEach((resolve) => {
            resolve(nickname);
        });
    }
    getNickname(accountId) {
        if (!accountId || typeof accountId !== "string")
            return Promise.resolve(undefined);
        if (accountId in this.cached) {
            debug(`name from cache for ${accountId}`);
            return Promise.resolve(this.cached[accountId]);
        }
        return new Promise((resolve) => {
            if (this.promises[accountId] instanceof Array) {
                debug(`waiting name for ${accountId}`);
                this.promises[accountId].push(resolve);
                return;
            }
            else {
                this.promises[accountId] = [resolve];
                this.queue.push(accountId);
                // do not launch directly but after timeout?
                // so that application have a chance to form first batch instead of send 1 nickname to resolve
                if (!this.h) {
                    this.h = setTimeout(this.processQueue.bind(this), 0);
                }
            }
        });
    }
    processQueue() {
        this.h = undefined;
        if (this.isFetching)
            return;
        if (!this.queue.length)
            return;
        const accounts = this.queue.splice(0, BATCH_SIZE);
        debug("accounts to fetch:", accounts);
        this.rawFetchNicknames(accounts).then((dict) => {
            for (let accountId in dict) {
                this.resolveNickname(accountId, dict[accountId]);
            }
        }).catch((error) => {
            console.error(error);
            for (let accountId of accounts) {
                this.resolveNickname(accountId, undefined);
            }
        }).then(() => {
            this.isFetching = false;
            this.processQueue();
        });
    }
    async rawFetchNicknames(accounts) {
        if (accounts.length <= 0 || accounts.length > BATCH_SIZE)
            throw "internal";
        if (this.getAppDelegate === undefined)
            throw "get app delegate undefined";
        const app = this.getAppDelegate();
        if (!app)
            throw "app is not initialized";
        const firestoreModule = await getFirebaseFirestore();
        const firestore = firestoreModule.getFirestore(app);
        const collection = firestoreModule.collection(firestore, "/names");
        const query = firestoreModule.query(collection, firestoreModule.where("accountId", "in", accounts), firestoreModule.limit(BATCH_SIZE));
        const snapshot = await firestoreModule.getDocs(query);
        const result = {};
        accounts.forEach((accountId) => { result[accountId] = undefined; });
        snapshot.forEach((doc) => {
            const accountId = doc.data()?.accountId;
            const nickname = doc.data()?.display;
            if (accountId && nickname)
                result[accountId] = nickname;
        });
        return result;
    }
}

// Copyright 2023, Alexander Nekrasov, All rights reserved.
import { SettingBase } from "./SettingBase";
export class SettingString extends SettingBase {
    constructor(domain, name, defaultValue) {
        super(domain, name, "SettingString");
        this.defaultValue = defaultValue;
        this.actualValue = defaultValue;
        this.pendingValue = undefined;
    }
    get dirty() {
        return this.pendingValue !== undefined;
    }
    get value() {
        return this.dirty && this.pendingValue !== undefined ? this.pendingValue : this.actualValue;
    }
    get error() {
        return this.value.length == 0 ? "cant_be_empty" : undefined;
    }
    fromDBJson(data) {
        this.Reset(data[this.name]);
        this.MarkInitialized();
    }
    toDBJson(data, force) {
        if (force === true) {
            data[this.name] = this.value;
        }
        else if (this.pendingValue !== undefined) {
            data[this.name] = this.pendingValue;
        }
    }
    Commit() {
        if (this.pendingValue !== undefined) {
            this.actualValue = this.pendingValue;
            this.pendingValue = undefined;
        }
    }
    Revert() {
        this.pendingValue = undefined;
    }
    Reset(value) {
        this.actualValue = value === undefined ? this.defaultValue : value;
        this.pendingValue = undefined;
    }
    SetValue(value) {
        const isActual = value === this.actualValue;
        this.pendingValue = isActual ? undefined : value;
    }
}

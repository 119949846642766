<template>
    <div class="content">
        <div class="planet">
            <span id="accordion-anchor" class="planet-button planet-button__safe" :class="{ 'focus__safe': safeFocus }"
                @click="openSafe">
                <svg width="60" height="58" viewBox="0 0 60 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="30.001" cy="29.001" r="29.001" fill="white" fill-opacity="0.18" />
                    <g filter="url(#filter0_f_908_35551)">
                        <path
                            d="M30.0464 17.4417L17.5905 39.4993L12.0018 39.5253L10 43.0703L47.4106 42.7021L49.4125 39.1572L42.8241 39.232L41.553 37.1215L37.6805 37.2075L38.8379 39.2897L23.2069 39.4241L24.4302 37.2577L35.1031 37.1807L33.3113 34.1558L28.6887 34.078L30.913 30.1391L29.6572 28.0014L30.8806 25.835L35.5789 33.813L39.6267 33.761L32.8874 22.2812L36.3022 16.2341L33.4662 11.3857L30.0464 17.4417Z"
                            fill="url(#paint0_linear_908_35551)" />
                        <path d="M14.4209 45.1122L15.0603 43.9798L45.5167 43.6457L46.166 44.7915L14.4209 45.1122Z"
                            fill="url(#paint1_linear_908_35551)" />
                    </g>
                    <path
                        d="M30.0464 17.4417L17.5905 39.4993L12.0018 39.5253L10 43.0703L47.4106 42.7021L49.4125 39.1572L42.8241 39.232L41.553 37.1215L37.6805 37.2075L38.8379 39.2897L23.2069 39.4241L24.4302 37.2577L35.1031 37.1807L33.3113 34.1558L28.6887 34.078L30.913 30.1391L29.6572 28.0014L30.8806 25.835L35.5789 33.813L39.6267 33.761L32.8874 22.2812L36.3022 16.2341L33.4662 11.3857L30.0464 17.4417Z"
                        fill="url(#paint2_linear_908_35551)" />
                    <path d="M14.4209 45.1122L15.0603 43.9798L45.5167 43.6457L46.166 44.7915L14.4209 45.1122Z"
                        fill="url(#paint3_linear_908_35551)" />
                    <defs>
                        <filter id="filter0_f_908_35551" x="0" y="1.38574" width="59.4121" height="53.7266"
                            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_908_35551" />
                        </filter>
                        <linearGradient id="paint0_linear_908_35551" x1="26.387" y1="51.8695" x2="42.0125" y2="24.199"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#EADCEA" />
                            <stop offset="1" stop-color="#8E54E9" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_908_35551" x1="26.387" y1="51.8695" x2="42.0125" y2="24.199"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#EADCEA" />
                            <stop offset="1" stop-color="#8E54E9" />
                        </linearGradient>
                        <linearGradient id="paint2_linear_908_35551" x1="26.387" y1="51.8695" x2="42.0125" y2="24.199"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#EADCEA" />
                            <stop offset="1" stop-color="#8E54E9" />
                        </linearGradient>
                        <linearGradient id="paint3_linear_908_35551" x1="26.387" y1="51.8695" x2="42.0125" y2="24.199"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#EADCEA" />
                            <stop offset="1" stop-color="#8E54E9" />
                        </linearGradient>
                    </defs>
                </svg>

            </span>
            <span class="planet-button planet-button__anonymous" :class="{ 'focus__anonymous': anonFocus }"
                @click="openAnonymous">
                <svg width="60" height="58" viewBox="0 0 60 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="30.003" cy="29.001" r="29.001" fill="white" fill-opacity="0.18" />
                    <g filter="url(#filter0_f_908_35554)">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M46.5833 13.7115L47.3726 13.1855L50.002 13.1855L45.9753 19.9962L43.5646 19.9962L43.4099 19.9962L43.33 20.1291L35.9646 32.9748L35.906 33.0705L35.938 33.1767L36.6313 35.8011L30.002 47L23.3779 35.8011L24.0713 33.1767L24.098 33.0705L24.0446 32.9748L16.6793 20.1291L16.5993 19.9962L16.4446 19.9962L14.034 19.9962L10.002 13.1855L12.6366 13.1855L13.4259 13.7115L14.8087 13.7115L30.0157 39.5624L45.2227 13.7115L46.5833 13.7115Z"
                            fill="url(#paint0_linear_908_35554)" />
                        <ellipse cx="30.2687" cy="17.7812" rx="4.78131" ry="4.8" transform="rotate(-90 30.2687 17.7812)"
                            fill="url(#paint1_linear_908_35554)" />
                    </g>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M46.5833 13.7115L47.3726 13.1855L50.002 13.1855L45.9753 19.9962L43.5646 19.9962L43.4099 19.9962L43.33 20.1291L35.9646 32.9748L35.906 33.0705L35.938 33.1767L36.6313 35.8011L30.002 47L23.3779 35.8011L24.0713 33.1767L24.098 33.0705L24.0446 32.9748L16.6793 20.1291L16.5993 19.9962L16.4446 19.9962L14.034 19.9962L10.002 13.1855L12.6366 13.1855L13.4259 13.7115L14.8087 13.7115L30.0157 39.5624L45.2227 13.7115L46.5833 13.7115Z"
                        fill="url(#paint2_linear_908_35554)" />
                    <ellipse cx="30.2687" cy="17.7812" rx="4.78131" ry="4.8" transform="rotate(-90 30.2687 17.7812)"
                        fill="url(#paint3_linear_908_35554)" />
                    <defs>
                        <filter id="filter0_f_908_35554" x="0.00195312" y="3" width="60" height="54"
                            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_908_35554" />
                        </filter>
                        <linearGradient id="paint0_linear_908_35554" x1="23.7688" y1="42.4483" x2="24.0673" y2="15.1353"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#EADCEA" />
                            <stop offset="1" stop-color="#8E54E9" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_908_35554" x1="26.7746" y1="16.2852" x2="34.4983" y2="16.3847"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#EADCEA" />
                            <stop offset="1" stop-color="#8E54E9" />
                        </linearGradient>
                        <linearGradient id="paint2_linear_908_35554" x1="23.7688" y1="42.4483" x2="24.0673" y2="15.1353"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#EADCEA" />
                            <stop offset="1" stop-color="#8E54E9" />
                        </linearGradient>
                        <linearGradient id="paint3_linear_908_35554" x1="26.7746" y1="16.2852" x2="34.4983" y2="16.3847"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#EADCEA" />
                            <stop offset="1" stop-color="#8E54E9" />
                        </linearGradient>
                    </defs>
                </svg>
            </span>
            <span class="planet-button planet-button__alerts" :class="{ 'focus__alerts': alertsFocus }" @click="openAlerts">
                <svg width="61" height="58" viewBox="0 0 61 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="30.0049" cy="29.001" r="29.001" fill="white" fill-opacity="0.18" />
                    <g filter="url(#filter0_f_908_35555)">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M12.6412 45.9085L13.4306 45.381L27.7399 45.381L28.1665 44.3316H30.0118H31.8518L32.2838 45.381H46.5877L47.3771 45.9085H50.0117L45.9797 39.0788H43.5691H43.4144L43.3344 38.9456L35.9691 26.0641L35.9158 25.9683L35.9425 25.8617L36.6358 23.23L30.0118 12L23.3825 23.23L24.0759 25.8617L24.1079 25.9683L24.0492 26.0641L16.6839 38.9456L16.6039 39.0788H16.4492H14.0386L10.0119 45.9085H12.6412ZM36.6412 33.592H37.5798L33.3985 26.5014L33.1425 27.4763L36.6412 33.592ZM26.6196 26.5014L26.8809 27.4763L23.3823 33.592H22.4383L26.6196 26.5014Z"
                            fill="url(#paint0_linear_908_35555)" />
                    </g>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12.6412 45.9085L13.4306 45.381L27.7399 45.381L28.1665 44.3316H30.0118H31.8518L32.2838 45.381H46.5877L47.3771 45.9085H50.0117L45.9797 39.0788H43.5691H43.4144L43.3344 38.9456L35.9691 26.0641L35.9158 25.9683L35.9425 25.8617L36.6358 23.23L30.0118 12L23.3825 23.23L24.0759 25.8617L24.1079 25.9683L24.0492 26.0641L16.6839 38.9456L16.6039 39.0788H16.4492H14.0386L10.0119 45.9085H12.6412ZM36.6412 33.592H37.5798L33.3985 26.5014L33.1425 27.4763L36.6412 33.592ZM26.6196 26.5014L26.8809 27.4763L23.3823 33.592H22.4383L26.6196 26.5014Z"
                        fill="url(#paint1_linear_908_35555)" />
                    <defs>
                        <filter id="filter0_f_908_35555" x="0.0117188" y="2" width="60" height="53.9087"
                            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_908_35555" />
                        </filter>
                        <linearGradient id="paint0_linear_908_35555" x1="19.9309" y1="9.88072" x2="19.6263" y2="46.7585"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#EADCEA" />
                            <stop offset="1" stop-color="#8E54E9" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_908_35555" x1="19.9309" y1="9.88072" x2="19.6263" y2="46.7585"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#EADCEA" />
                            <stop offset="1" stop-color="#8E54E9" />
                        </linearGradient>
                    </defs>
                </svg>

            </span>
            <span class="planet-button planet-button__web" :class="{ 'focus__web': webFocus }" @click="openWeb">
                <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="29.0069" cy="29.001" r="29.001" fill="white" fill-opacity="0.18" />
                    <g filter="url(#filter0_f_908_35556)">
                        <path
                            d="M43.0803 39.989L30.7105 18.8388L31.4219 10.7563L13.514 42.2017L26.8039 21.4752L27.6236 22.9097L28.0386 23.5694L29.2535 25.6466L24.1212 34.604L27.5217 34.5794L29.8375 30.7539L32.0464 34.5308L34.4444 34.5221L35.6593 36.5994L26.7041 36.6415L24.7766 40.0497L37.6523 40.007L41.0435 45.8054L46.4764 45.7958L43.0803 39.989Z"
                            fill="url(#paint0_linear_908_35556)" />
                        <path d="M30.7105 18.8388L31.1138 19.5285L28.372 24.1394L30.7105 18.8388Z"
                            fill="url(#paint1_linear_908_35556)" />
                        <ellipse cx="14.8146" cy="40.1249" rx="2.4591" ry="2.4591" transform="rotate(-90 14.8146 40.1249)"
                            fill="url(#paint2_linear_908_35556)" />
                        <ellipse cx="17.2743" cy="35.8194" rx="1.43447" ry="1.43447" transform="rotate(-90 17.2743 35.8194)"
                            fill="url(#paint3_linear_908_35556)" />
                    </g>
                    <path
                        d="M43.0803 39.989L30.7105 18.8388L31.4219 10.7563L13.514 42.2017L26.8039 21.4752L27.6236 22.9097L28.0386 23.5694L29.2535 25.6466L24.1212 34.604L27.5217 34.5794L29.8375 30.7539L32.0464 34.5308L34.4444 34.5221L35.6593 36.5994L26.7041 36.6415L24.7766 40.0497L37.6523 40.007L41.0435 45.8054L46.4764 45.7958L43.0803 39.989Z"
                        fill="url(#paint4_linear_908_35556)" />
                    <path d="M30.7105 18.8388L31.1138 19.5285L28.372 24.1394L30.7105 18.8388Z"
                        fill="url(#paint5_linear_908_35556)" />
                    <ellipse cx="14.8146" cy="40.1249" rx="2.4591" ry="2.4591" transform="rotate(-90 14.8146 40.1249)"
                        fill="url(#paint6_linear_908_35556)" />
                    <ellipse cx="17.2743" cy="35.8194" rx="1.43447" ry="1.43447" transform="rotate(-90 17.2743 35.8194)"
                        fill="url(#paint7_linear_908_35556)" />
                    <defs>
                        <filter id="filter0_f_908_35556" x="2.35547" y="0.756348" width="54.1211" height="55.0488"
                            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_908_35556" />
                        </filter>
                        <linearGradient id="paint0_linear_908_35556" x1="16.0889" y1="20.1675" x2="31.6064" y2="46.6995"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#EADCEA" />
                            <stop offset="1" stop-color="#8E54E9" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_908_35556" x1="16.0889" y1="20.1675" x2="31.6064" y2="46.6995"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#EADCEA" />
                            <stop offset="1" stop-color="#8E54E9" />
                        </linearGradient>
                        <linearGradient id="paint2_linear_908_35556" x1="12.4052" y1="40.489" x2="16.3995" y2="40.489"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#EADCEA" />
                            <stop offset="1" stop-color="#8E54E9" />
                        </linearGradient>
                        <linearGradient id="paint3_linear_908_35556" x1="15.8689" y1="36.0319" x2="18.1988" y2="36.0319"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#EADCEA" />
                            <stop offset="1" stop-color="#8E54E9" />
                        </linearGradient>
                        <linearGradient id="paint4_linear_908_35556" x1="16.0889" y1="20.1675" x2="31.6064" y2="46.6995"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#EADCEA" />
                            <stop offset="1" stop-color="#8E54E9" />
                        </linearGradient>
                        <linearGradient id="paint5_linear_908_35556" x1="16.0889" y1="20.1675" x2="31.6064" y2="46.6995"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#EADCEA" />
                            <stop offset="1" stop-color="#8E54E9" />
                        </linearGradient>
                        <linearGradient id="paint6_linear_908_35556" x1="12.4052" y1="40.489" x2="16.3995" y2="40.489"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#EADCEA" />
                            <stop offset="1" stop-color="#8E54E9" />
                        </linearGradient>
                        <linearGradient id="paint7_linear_908_35556" x1="15.8689" y1="36.0319" x2="18.1988" y2="36.0319"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#EADCEA" />
                            <stop offset="1" stop-color="#8E54E9" />
                        </linearGradient>
                    </defs>
                </svg>
            </span>

            <span class="planet-dark__top"></span>
            <span class="planet-dark__bottom"></span>
            <span id="accordion-anchor_mobile"></span>
        </div>
        <span class="planet-light"></span>
    </div>
</template>
<script>
import openAccordion from '@/mixins/openAccordion';
export default {
    props: {
        safeFocus: {
            type: Boolean,
            default: true
        },

        anonFocus: {
            type: Boolean,
            default: false
        },

        alertsFocus: {
            type: Boolean,
            default: false
        },

        webFocus: {
            type: Boolean,
            default: false
        }
    },

    mixins: [openAccordion],

}
</script>
<style scoped lang="scss">
.content {
    position: relative;
    z-index: 0;
}

.planet {
    height: 675px;
    width: 675px;
    position: relative;
    top: 218px;
    left: 20px;
    border-radius: 675.482px;
    border: 1px solid #A165C7;
    background: url("../../../public/images/planetBack2.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 582px;
    z-index: 2;
}

.planet-button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 96px;
    width: 96px;
    border-radius: 50%;
    cursor: pointer;

    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.planet-button__safe svg,
.planet-button__anonymous svg,
.planet-button__alerts svg,
.planet-button__web svg {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.planet-button__safe {
    top: -49px;
    left: 41%;
}

.planet-button__safe:hover svg,
.planet .focus__safe svg {
    height: 112px;
    width: 112px;
}

.planet-button__anonymous {
    top: 67.25px;
    left: 31px;
}

.planet-button__anonymous:hover svg,
.planet .focus__anonymous svg {
    width: 112px;
    height: 112px;
}

.planet-button__alerts {
    top: 279.25px;
    left: -47px;
}

.planet-button__alerts:hover svg,
.planet .focus__alerts svg {
    width: 163px;
    height: 112px;
}

.planet-button__web {
    bottom: 105.24px;
    left: 0;
}

.planet-button__web:hover svg,
.planet .focus__web svg {
    width: 112px;
    height: 112px;
}

.planet-button__safe:hover svg #paint0_linear_908_35551 stop:not(:last-child),
.planet .focus__safe svg #paint0_linear_908_35551 stop:not(:last-child),

.planet-button__anonymous:hover svg #paint0_linear_908_35554 stop:not(:last-child),
.planet .focus__anonymous svg #paint0_linear_908_35554 stop:not(:last-child),

.planet-button__alerts:hover svg #paint0_linear_908_35555 stop:not(:last-child),
.planet .focus__alerts svg #paint0_linear_908_35555 stop:not(:last-child),

.planet-button__web:hover svg #paint0_linear_908_35556 stop:not(:last-child),
.planet .focus__web svg #paint0_linear_908_35556 stop:not(:last-child),

.planet-button__safe:hover svg #paint1_linear_908_35551 stop:not(:last-child),
.planet .focus__safe svg #paint1_linear_908_35551 stop:not(:last-child),

.planet-button__anonymous:hover svg #paint1_linear_908_35554 stop:not(:last-child),
.planet .focus__anonymous svg #paint1_linear_908_35554 stop:not(:last-child),

.planet-button__alerts:hover svg #paint1_linear_908_35555 stop:not(:last-child),
.planet .focus__alerts svg #paint1_linear_908_35555 stop:not(:last-child),

.planet-button__web:hover svg #paint1_linear_908_35556 stop:not(:last-child),
.planet .focus__web svg #paint1_linear_908_35556 stop:not(:last-child),

.planet-button__safe:hover svg #paint2_linear_908_35551 stop:not(:last-child),
.planet .focus__safe svg #paint2_linear_908_35551 stop:not(:last-child),

.planet-button__anonymous:hover svg #paint2_linear_908_35554 stop:not(:last-child),
.planet .focus__anonymous svg #paint2_linear_908_35554 stop:not(:last-child),

.planet-button__web:hover svg #paint2_linear_908_35556 stop:not(:last-child),
.planet .focus__web svg #paint2_linear_908_35556 stop:not(:last-child),

.planet-button__safe:hover svg #paint3_linear_908_35551 stop:not(:last-child),
.planet .focus__safe svg #paint3_linear_908_35551 stop:not(:last-child),

.planet-button__anonymous:hover svg #paint3_linear_908_35554 stop:not(:last-child),
.planet .focus__anonymous svg #paint3_linear_908_35554 stop:not(:last-child),

.planet-button__web:hover svg #paint3_linear_908_35556 stop:not(:last-child),
.planet .focus__web svg #paint3_linear_908_35556 stop:not(:last-child),
.planet-button__web:hover svg #paint4_linear_908_35556 stop:not(:last-child),
.planet .focus__web svg #paint4_linear_908_35556 stop:not(:last-child),
.planet-button__web:hover svg #paint5_linear_908_35556 stop:not(:last-child),
.planet .focus__web svg #paint5_linear_908_35556 stop:not(:last-child),
.planet-button__web:hover svg #paint6_linear_908_35556 stop:not(:last-child),
.planet .focus__web svg #paint6_linear_908_35556 stop:not(:last-child),
.planet-button__web:hover svg #paint7_linear_908_35556 stop:not(:last-child),
.planet .focus__web svg #paint7_linear_908_35556 stop:not(:last-child) {
    stop-color: #D574DE;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
}



.planet-button__safe svg #paint0_linear_908_35551 stop:not(:last-child),

.planet-button__anonymous svg #paint0_linear_908_35554 stop:not(:last-child),

.planet-button__alerts svg #paint0_linear_908_35555 stop:not(:last-child),

.planet-button__web svg #paint0_linear_908_35556 stop:not(:last-child),

.planet-button__safe svg #paint1_linear_908_35551 stop:not(:last-child),

.planet-button__anonymous svg #paint1_linear_908_35554 stop:not(:last-child),

.planet-button__alerts svg #paint1_linear_908_35555 stop:not(:last-child),

.planet-button__web svg #paint1_linear_908_35556 stop:not(:last-child),

.planet-button__safe svg #paint2_linear_908_35551 stop:not(:last-child),

.planet-button__anonymous svg #paint2_linear_908_35554 stop:not(:last-child),

.planet-button__web svg #paint2_linear_908_35556 stop:not(:last-child),

.planet-button__safe svg #paint3_linear_908_35551 stop:not(:last-child),

.planet-button__anonymous svg #paint3_linear_908_35554 stop:not(:last-child),
.planet-button__web svg #paint3_linear_908_35556 stop:not(:last-child),
.planet-button__web svg #paint4_linear_908_35556 stop:not(:last-child),
.planet-button__web svg #paint5_linear_908_35556 stop:not(:last-child),
.planet-button__web svg #paint6_linear_908_35556 stop:not(:last-child),
.planet-button__web svg #paint7_linear_908_35556 stop:not(:last-child) {
    stop-color: #EADCEA;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: all 1s linear;
    -moz-transition: all 1s linear;
    -ms-transition: all 1s linear;
    -o-transition: all 1s linear;
    transition: all 1s linear;
}

.planet-button__safe svg circle,
.planet-button__anonymous svg circle,
.planet-button__alerts svg circle,
.planet-button__web svg circle {
    fill-opacity: 0.18;
    fill: white;
    transition: all 0.6s linear;
}

.planet-button__safe:hover svg circle,
.planet .focus__safe svg circle,

.planet-button__anonymous:hover svg circle,
.planet .focus__anonymous svg circle,

.planet-button__alerts:hover svg circle,
.planet .focus__alerts svg circle,

.planet-button__web:hover svg circle,
.planet .focus__web svg circle {
    fill-opacity: 0;
    fill: transparent;
    transition: all 0.3s linear;
}

.planet-dark__top {
    position: absolute;
    top: 0;
    left: 58%;
    width: 411.162px;
    height: 520.023px;
    transform: rotate(-178.811deg);
    flex-shrink: 0;
    border-radius: 520.023px;
    background: #07041B;
    filter: blur(100px);
}

.planet-dark__bottom {
    position: absolute;
    left: 85px;
    top: 284px;
    width: 831.058px;
    height: 520.023px;
    transform: rotate(143.911deg);
    border-radius: 831.058px;
    background: #07041B;
    filter: blur(100px);
}

.planet-light {
    position: absolute;
    left: -40%;
    right: 0;
    top: -100px;
    bottom: -300px;
    background: url('../../../public/images/planetLight.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
}

#accordion-anchor_mobile {
    position: absolute;
    bottom: 0;
}

@media (min-width: 1800px) {
    .planet {
        width: 886.36px;
        height: 886.36px;
        top: 123px;
        left: 67px;
        background-size: 782px;
    }

    .planet-button {
        width: 124px;
        height: 124px;
    }

    .planet-button svg {
        width: 76px;
        height: 76px;
    }

    .planet-button__safe {
        top: -69px;
    }

    .planet-button__safe:hover svg,
    .planet .focus__safe svg {
        height: 146px;
        width: 146px;
    }

    .planet-button__anonymous {
        top: 88.25px;
        left: 46px;
    }

    .planet-button__anonymous:hover svg,
    .planet .focus__anonymous svg {
        height: 146px;
        width: 146px;
    }

    .planet-button__alerts {
        top: 365.25px;
        left: -64px;
    }

    .planet-button__alerts:hover svg,
    .planet .focus__alerts svg {
        height: 146px;
        width: 190px;
    }

    .planet-button__web {
        bottom: 118px;
        left: 21px;
    }

    .planet-button__web:hover svg,
    .planet .focus__web svg {
        height: 146px;
        width: 146px;
    }

    .planet-dark__top {
        width: 539.523px;
        height: 682.369px;
    }

    .planet-dark__bottom {
        width: 1090.505px;
        height: 682.369px;
    }
}

@media (min-width: 0px) and (max-width: 1290px) {

    .content {
        position: initial;
    }

    .planet {
        top: 63px;
        left: 40px;
        width: 585.979px;
        height: 585.979px;
        border-radius: 585.979px;
        background-size: 508px;
    }

    .planet-light {
        background-size: 655px;
        background-position: top;
    }

    .planet-button {
        width: 80px;
        height: 80px;
    }

    .planet-button svg {
        width: 50px;
        height: 50px;
    }

    .planet-button__safe {
        top: -41px;
    }

    .planet-button__safe:hover svg,
    .planet .focus__safe svg {
        height: 80px;
        width: 80px;
    }

    .planet-button__anonymous {
        top: 50.25px;
        left: 39px;
    }

    .planet-button__anonymous:hover svg,
    .planet .focus__anonymous svg {
        height: 80px;
        width: 80px;
    }

    .planet-button__alerts {
        top: 237.25px;
        left: -41px;
    }

    .planet-button__alerts:hover svg,
    .planet .focus__alerts svg {
        height: 80px;
        width: 80px;
    }

    .planet-button__web {
        bottom: 86.24px;
        left: 10.95px;
    }

    .planet-button__web:hover svg,
    .planet .focus__web svg {
        height: 80px;
        width: 80px;
    }

    .planet-dark__top {
        width: 356.683px;
        height: 451.119px;
    }

    .planet-dark__bottom {
        width: 720.941px;
        height: 451.119px;
    }
}

@media (max-width: 680px) {
    .planet {
        width: 270px;
        height: 270px;
        border-radius: 270px;
        left: 17px;
        top: 37px;
        background-size: 246px;
    }

    .planet-light {
        background-size: 300px;
        top: 0px;
        bottom: 0px;
        left: -150px;
    }

    .planet-button {
        width: 40px;
        height: 40px;
    }

    .planet-button svg {
        width: 24px;
        height: 24px;
    }

    .planet-button__safe {
        top: -20px;
        left: 41%;
    }

    .planet-button__safe:hover svg,
    .planet .focus__safe svg {
        width: 40px;
        height: 40px;
    }

    .planet-button__anonymous {
        top: 25.25px;
        left: 15.95px;
    }

    .planet-button__anonymous:hover svg,
    .planet .focus__anonymous svg {
        width: 40px;
        height: 40px;
    }

    .planet-button__alerts {
        top: 41%;
        left: -21px;
    }

    .planet-button__alerts:hover svg,
    .planet .focus__alerts svg {
        width: 40px;
        height: 40px;
    }

    .planet-button__web {
        bottom: 44.24px;
        left: 0;
    }

    .planet-button__web:hover svg,
    .planet .focus__web svg {
        width: 40px;
        height: 40px;
    }

    .planet-dark__top {
        display: block;
        width: 206.181px;
        height: 260.77px;
        filter: blur(48px);
    }

    .planet-dark__bottom {
        display: block;
        left: 15px;
        top: 110px;
        width: 349.631px;
        height: 218.777px;
        filter: blur(48px);
    }
}
</style>
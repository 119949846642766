// Copyright 2023, Alexander Nekrasov, All rights reserved.

import { sharedState } from "../sharedState";

export class DonationLimit {
  constructor(ticker) {
    this.ticker = ticker;
    this.systemValue = undefined;
    this.currentValue = undefined;
    this.pendingValue = undefined;
  }

  get placeholder() {
    return this.currentValue || this.systemValue;
  }

  get value() {
    return this.pendingValue === undefined ? this.currentValue : this.pendingValue;
  }

  SetValue(value) {
    this.pendingValue = value;
    this.OnChange();
  }

  get canSave() {
    return Number(this.pendingValue) > this.systemValue;
  }

  OnChange() {
    if (this.pendingValue && isNaN(this.pendingValue)) {
      this.pendingValue = this.valid;
    } else {
      this.valid = this.pendingValue;
    }

    if (this.currentValue === Number(this.pendingValue)) {
      this.Revert();
    }
  }

  Revert() {
    this.pendingValue = undefined;
  }
}

export class LimitsHelper {
  constructor() {
    this.tickers = [];
    this.systemLimits = {};
    this.currentLimits = {};
    this.limits = [];
    this.loading = true;
    this.pendingOperation = false;
  }

  get canSave() {
    for (let limit of this.limits) {
      if (limit.canSave) return true;
    }
    return false;
  }

  Reset() {
    this.limits = [];
    this.currentLimits = {};
    this.pendingOperation = false;
  }

  UpdateSettings(settings) {
    this.tickers = settings.tickerOrder.map((ticker) => ticker);
    this.tickers.forEach((ticker) => {
      this.systemLimits[ticker] = settings.limits[ticker];
    });
    //console.log(this.tickers);
    this.updateInternalState();
  }

  UpdateCurrentLimits(currentLimits) {
    this.loading = false;

    this.currentLimits = {};
    for (let ticker in currentLimits) {
      this.currentLimits[ticker] = currentLimits[ticker];
    }

    this.Revert();
    this.pendingOperation = false;

    this.updateInternalState();
  }

  updateInternalState() {
    this.tickers.forEach((ticker) => {
      const value = this.currentLimits[ticker];
      const limit = this.findOrCreateLimit(ticker);
      limit.systemValue = this.systemLimits[ticker] || 0;
      limit.currentValue = value;
      limit.pendingValue = undefined;
    });
  }

  findOrCreateLimit(ticker) {
    let limit = this.limits.find((limit) => limit.ticker === ticker);
    if (!limit) {
      limit = new DonationLimit(ticker);
      this.limits.push(limit);
    }
    return limit;
  }

  Revert() {
    this.limits.forEach((limit) => {
      limit.pendingValue = undefined;
    });
  }

  async Commit() {
    if (this.pendingOperation) return;

    const limits = [];
    for (let limit of this.limits) {
      if (limit.canSave) {
        limits.push({
          ticker: limit.ticker,
          value: Number(limit.pendingValue),
        });
      }
    }
    if (!Object.keys(limits).length) return;

    this.pendingOperation = true;
    try {
      await sharedState.httpCallableDelegate("updateLimits", { limits });
    } catch (error) {
      this.pendingOperation = false;
      console.error(error);
    }
  }
}

// Copyright 2023, Alexander Nekrasov, All rights reserved.
export function MetaSetting(enabled, setting, params) {
    const handler = {
        get: function (target, prop, receiver) {
            if (prop === "disabled" && params?.disable && enabled.value === true) {
                return true;
            }
            if (prop === "hidden" && params?.hide && enabled.value === true) {
                return true;
            }
            if (prop === "value" && enabled.value === true) {
                return setting.default;
            }
            if (prop === "error" && enabled.value === true) {
                return undefined;
            }
            if (prop === "dirty" && enabled.value === true) {
                return false;
            }
            return Reflect.get(target, prop, receiver);
        },
    };
    return new Proxy(setting, handler);
}

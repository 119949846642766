<template>
    <ul class="blockchain-list">
        <supportedWalletsItem :supportedWallet="supportedWallet" v-for="supportedWallet in supportedWallets" :key="supportedWallet.id"/>
    </ul>
</template>
<script>
import supportedWalletsItem from '@/components/landing/supportedWalletsItem.vue';
import { wallets } from '@/data/supportedWallets';

export default {
    components: { supportedWalletsItem },

    computed: {
        supportedWallets() {
            return wallets
        }
    }
}
</script>
<style scoped lang="scss">
.blockchain-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
}

@media (min-width: 1800px) {
    .blockchain-list {
        gap: 30px;
    }
}
</style>
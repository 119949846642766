// Copyright 2023, Alexander Nekrasov, All rights reserved.
import { SettingBase } from "./SettingBase";
export class SettingChoice extends SettingBase {
    constructor(domain, name, options) {
        if (options.length <= 0)
            throw "no valid options";
        super(domain, name, "SettingChoice");
        this.options = options;
        this.actualValue = options[0];
        this.pendingValue = undefined;
    }
    get dirty() {
        return this.pendingValue !== undefined;
    }
    get value() {
        return this.dirty && this.pendingValue !== undefined ? this.pendingValue : this.actualValue;
    }
    fromDBJson(data) {
        this.Reset(data[this.name]);
        this.MarkInitialized();
    }
    toDBJson(data, force) {
        if (force === true) {
            data[this.name] = this.value;
        }
        else if (this.pendingValue !== undefined) {
            data[this.name] = this.pendingValue;
        }
    }
    Commit() {
        if (this.pendingValue !== undefined) {
            this.actualValue = this.pendingValue;
            this.pendingValue = undefined;
        }
    }
    Revert() {
        this.pendingValue = undefined;
    }
    Reset(value) {
        this.pendingValue = undefined;
        if (value === undefined) {
            this.actualValue = this.options[0];
        }
        else if (this.options.includes(value)) {
            this.actualValue = value;
        }
        else {
            console.error(`[${this.displayName}] unexpected value: ${typeof value} ${value}`);
            this.actualValue = this.options[0];
            this.pendingValue = this.options[0]; // force dirty to re-save
        }
    }
    SetValue(value) {
        if (this.options.includes(value)) {
            const isActual = value === this.actualValue;
            this.pendingValue = isActual ? undefined : value;
        }
        else {
            console.error(`unexpected value ${value}`);
        }
    }
}

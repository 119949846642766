<template>
  <li class="about-list__item" @click="click">
    <span class="about-list__item-lighter"></span>
    <a :href="isMobile ? '#accordion-anchor_mobile' : '#accordion-anchor'" class="about-list__item-cart">
      <span class="cart-icon" :class="aboutListItem.className">
        <img src="../../../public/images/aboutBorder.webp" class="svg" alt="border">

        <svg v-if="aboutListItem.id == 1" xmlns="http://www.w3.org/2000/svg" width="100" height="91" viewBox="0 0 100 91"
          fill="none">
          <g filter="url(#filter0_f_908_28558)">
            <path
              d="M50.4528 25.0363L25.3173 69.5478L14.0396 69.6003L10 76.7539L85.4933 76.0109L89.533 68.8573L76.2379 69.0082L73.6728 64.7495L65.8582 64.923L68.1939 69.1248L36.651 69.396L39.1197 65.0243L60.6571 64.8688L57.0414 58.7648L47.7132 58.6077L52.2016 50.6592L49.6676 46.3454L52.1363 41.9737L61.6174 58.0729L69.7856 57.9681L56.186 34.8023L63.077 22.5993L57.3539 12.8155L50.4528 25.0363Z"
              fill="url(#paint0_linear_908_28558)" />
            <path d="M18.9212 80.8744L20.2116 78.5892L81.6715 77.915L82.9816 80.2272L18.9212 80.8744Z"
              fill="url(#paint1_linear_908_28558)" />
          </g>
          <path
            d="M50.4343 24.945L25.2714 69.5048L13.9815 69.5573L9.9375 76.7188L85.5128 75.975L89.5569 68.8136L76.2474 68.9647L73.6794 64.7013L65.8563 64.875L68.1946 69.0814L36.6174 69.3528L39.0888 64.9764L60.6496 64.8208L57.03 58.7101L47.6916 58.5528L52.185 50.5957L49.6482 46.2772L52.1195 41.9008L61.6109 58.0175L69.788 57.9125L56.1737 34.7216L63.0721 22.5053L57.3428 12.7109L50.4343 24.945Z"
            fill="url(#paint2_linear_908_28558)" />
          <path d="M18.8683 80.8437L20.1602 78.556L81.6869 77.8811L82.9984 80.1959L18.8683 80.8437Z"
            fill="url(#paint3_linear_908_28558)" />
          <defs>
            <filter id="filter0_f_908_28558" x="0" y="2.81543" width="99.5332" height="88.0591"
              filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_908_28558" />
            </filter>
            <linearGradient id="paint0_linear_908_28558" x1="43.0685" y1="94.5103" x2="74.6001" y2="38.6723"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#656565" />
              <stop offset="1" stop-color="#EAEAEA" />
            </linearGradient>
            <linearGradient id="paint1_linear_908_28558" x1="43.0685" y1="94.5103" x2="74.6001" y2="38.6723"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#656565" />
              <stop offset="1" stop-color="#EAEAEA" />
            </linearGradient>
            <linearGradient id="paint2_linear_908_28558" x1="43.0419" y1="94.4945" x2="74.6078" y2="38.5958"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#656565" />
              <stop offset="1" stop-color="#EAEAEA" />
            </linearGradient>
            <linearGradient id="paint3_linear_908_28558" x1="43.0419" y1="94.4945" x2="74.6078" y2="38.5958"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#656565" />
              <stop offset="1" stop-color="#EAEAEA" />
            </linearGradient>
          </defs>
        </svg>

        <svg v-if="aboutListItem.id == 2" xmlns="http://www.w3.org/2000/svg" width="120" height="108"
          viewBox="0 0 120 108" fill="none">
          <g filter="url(#filter0_f_908_28609)">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M93.9917 20.4658L95.6099 19.383L101 19.383L92.7453 33.4047L87.8035 33.4047L87.4863 33.4047L87.3224 33.6782L72.2235 60.1248L72.1032 60.3216L72.1688 60.5404L73.5901 65.9435L60 88.9995L46.4208 65.9435L47.8421 60.5404L47.8968 60.3216L47.7874 60.1248L32.6885 33.6782L32.5245 33.4047L32.2074 33.4047L27.2656 33.4047L19 19.383L24.4011 19.383L26.0192 20.4658L28.8509 20.4658L60.0255 73.6875L91.2 20.4658L93.9917 20.4658Z"
              fill="url(#paint0_linear_908_28609)" />
            <ellipse cx="60.5451" cy="28.8438" rx="9.84366" ry="9.84" transform="rotate(-90 60.5451 28.8438)"
              fill="url(#paint1_linear_908_28609)" />
          </g>
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M93.9917 20.4658L95.6099 19.383L101 19.383L92.7453 33.4047L87.8035 33.4047L87.4863 33.4047L87.3224 33.6782L72.2235 60.1248L72.1032 60.3216L72.1688 60.5404L73.5901 65.9435L60 88.9995L46.4208 65.9435L47.8421 60.5404L47.8968 60.3216L47.7874 60.1248L32.6885 33.6782L32.5245 33.4047L32.2074 33.4047L27.2656 33.4047L19 19.383L24.4011 19.383L26.0192 20.4658L28.8509 20.4658L60.0255 73.6875L91.2 20.4658L93.9917 20.4658Z"
            fill="url(#paint2_linear_908_28609)" />
          <ellipse cx="60.5451" cy="28.8438" rx="9.84366" ry="9.84" transform="rotate(-90 60.5451 28.8438)"
            fill="url(#paint3_linear_908_28609)" />
          <defs>
            <filter id="filter0_f_908_28609" x="0" y="0" width="120" height="108" filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="9.5" result="effect1_foregroundBlur_908_28609" />
            </filter>
            <linearGradient id="paint0_linear_908_28609" x1="66.0714" y1="88.2953" x2="66.0714" y2="31.757"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#656565" />
              <stop offset="1" stop-color="#EAEAEA" />
            </linearGradient>
            <linearGradient id="paint1_linear_908_28609" x1="50.9006" y1="30.301" x2="66.8894" y2="30.301"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#656565" />
              <stop offset="1" stop-color="#EAEAEA" />
            </linearGradient>
            <linearGradient id="paint2_linear_908_28609" x1="66.0714" y1="88.2953" x2="66.0714" y2="31.757"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#656565" />
              <stop offset="1" stop-color="#EAEAEA" />
            </linearGradient>
            <linearGradient id="paint3_linear_908_28609" x1="50.9006" y1="30.301" x2="66.8894" y2="30.301"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#656565" />
              <stop offset="1" stop-color="#EAEAEA" />
            </linearGradient>
          </defs>
        </svg>

        <svg v-if="aboutListItem.id == 3" xmlns="http://www.w3.org/2000/svg" width="112" height="100"
          viewBox="0 0 112 100" fill="none">
          <g filter="url(#filter0_f_908_28745)">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M20.3903 85L22.0085 83.9112H51.3427L52.2173 81.7447H56.0002H59.7722L60.6578 83.9112H89.981L91.5991 85H97.0002L88.7346 70.901H83.7928H83.4757L83.3117 70.6261L68.2128 44.0338L68.1034 43.8358L68.1581 43.6159L69.5794 38.183L56.0002 15L42.4101 38.183L43.8314 43.6159L43.897 43.8358L43.7767 44.0338L28.6778 70.6261L28.5138 70.901H28.1967H23.2549L15.0002 85H20.3903ZM69.5896 59.5732H71.5139L62.9422 44.9354L62.4173 46.9479L69.5896 59.5732ZM49.0465 44.9354L49.5822 46.9479L42.41 59.5732H40.4748L49.0465 44.9354Z"
              fill="url(#paint0_linear_908_28745)" />
          </g>
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M20.3903 85L22.0085 83.9112H51.3427L52.2173 81.7447H56.0002H59.7722L60.6578 83.9112H89.981L91.5991 85H97.0002L88.7346 70.901H83.7928H83.4757L83.3117 70.6261L68.2128 44.0338L68.1034 43.8358L68.1581 43.6159L69.5794 38.183L56.0002 15L42.4101 38.183L43.8314 43.6159L43.897 43.8358L43.7767 44.0338L28.6778 70.6261L28.5138 70.901H28.1967H23.2549L15.0002 85H20.3903ZM69.5896 59.5732H71.5139L62.9422 44.9354L62.4173 46.9479L69.5896 59.5732ZM49.0465 44.9354L49.5822 46.9479L42.41 59.5732H40.4748L49.0465 44.9354Z"
            fill="url(#paint1_linear_908_28745)" />
          <defs>
            <filter id="filter0_f_908_28745" x="0" y="0" width="112" height="100" filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_908_28745" />
            </filter>
            <linearGradient id="paint0_linear_908_28745" x1="49.9286" y1="15.7081" x2="49.9286" y2="72.5578"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.0989583" stop-color="#656565" />
              <stop offset="1" stop-color="#EAEAEA" />
            </linearGradient>
            <linearGradient id="paint1_linear_908_28745" x1="49.9286" y1="15.7081" x2="49.9286" y2="72.5578"
              gradientUnits="userSpaceOnUse">
              <stop offset="0.0989583" stop-color="#656565" />
              <stop offset="1" stop-color="#EAEAEA" />
            </linearGradient>
          </defs>
        </svg>

        <svg v-if="aboutListItem.id == 4" xmlns="http://www.w3.org/2000/svg" width="97" height="99" viewBox="0 0 97 99"
          fill="none">
          <g filter="url(#filter0_f_908_28791)">
            <path
              d="M76.4004 73.7105L51.0809 30.4188L52.5371 13.875L15.8819 78.2396L43.0846 35.8152L44.7624 38.7514L45.6121 40.1017L48.0988 44.3536L37.5936 62.6882L44.5539 62.6378L49.2941 54.8076L53.8155 62.5382L58.7239 62.5206L61.2107 66.7725L42.8804 66.8587L38.9351 73.8349L65.29 73.7474L72.2314 85.6159L83.3519 85.5964L76.4004 73.7105Z"
              fill="url(#paint0_linear_908_28791)" />
            <path d="M51.0809 30.4188L51.9065 31.8306L46.2944 41.2683L51.0809 30.4188Z"
              fill="url(#paint1_linear_908_28791)" />
            <ellipse cx="18.5471" cy="73.9861" rx="5.03347" ry="5.03347" transform="rotate(-90 18.5471 73.9861)"
              fill="url(#paint2_linear_908_28791)" />
            <ellipse cx="23.5807" cy="65.1771" rx="2.93619" ry="2.93619" transform="rotate(-90 23.5807 65.1771)"
              fill="url(#paint3_linear_908_28791)" />
          </g>
          <path
            d="M76.4004 73.7105L51.0809 30.4188L52.5371 13.875L15.8819 78.2396L43.0846 35.8152L44.7624 38.7514L45.6121 40.1017L48.0988 44.3536L37.5936 62.6882L44.5539 62.6378L49.2941 54.8076L53.8155 62.5382L58.7239 62.5206L61.2107 66.7725L42.8804 66.8587L38.9351 73.8349L65.29 73.7474L72.2314 85.6159L83.3519 85.5964L76.4004 73.7105Z"
            fill="url(#paint4_linear_908_28791)" />
          <path d="M51.0809 30.4188L51.9065 31.8306L46.2944 41.2683L51.0809 30.4188Z"
            fill="url(#paint5_linear_908_28791)" />
          <ellipse cx="18.5471" cy="73.9861" rx="5.03347" ry="5.03347" transform="rotate(-90 18.5471 73.9861)"
            fill="url(#paint6_linear_908_28791)" />
          <ellipse cx="23.5807" cy="65.1771" rx="2.93619" ry="2.93619" transform="rotate(-90 23.5807 65.1771)"
            fill="url(#paint7_linear_908_28791)" />
          <defs>
            <filter id="filter0_f_908_28791" x="0.513672" y="0.875" width="95.8379" height="97.7407"
              filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="6.5" result="effect1_foregroundBlur_908_28791" />
            </filter>
            <linearGradient id="paint0_linear_908_28791" x1="21.1525" y1="33.1384" x2="52.9148" y2="87.4462"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#656565" />
              <stop offset="1" stop-color="#EAEAEA" />
            </linearGradient>
            <linearGradient id="paint1_linear_908_28791" x1="21.1525" y1="33.1384" x2="52.9148" y2="87.4462"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#656565" />
              <stop offset="1" stop-color="#EAEAEA" />
            </linearGradient>
            <linearGradient id="paint2_linear_908_28791" x1="13.6155" y1="74.7314" x2="21.7913" y2="74.7314"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#656565" />
              <stop offset="1" stop-color="#EAEAEA" />
            </linearGradient>
            <linearGradient id="paint3_linear_908_28791" x1="20.7039" y1="65.6119" x2="25.4731" y2="65.6119"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#656565" />
              <stop offset="1" stop-color="#EAEAEA" />
            </linearGradient>
            <linearGradient id="paint4_linear_908_28791" x1="21.1525" y1="33.1384" x2="52.9148" y2="87.4462"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#656565" />
              <stop offset="1" stop-color="#EAEAEA" />
            </linearGradient>
            <linearGradient id="paint5_linear_908_28791" x1="21.1525" y1="33.1384" x2="52.9148" y2="87.4462"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#656565" />
              <stop offset="1" stop-color="#EAEAEA" />
            </linearGradient>
            <linearGradient id="paint6_linear_908_28791" x1="13.6155" y1="74.7314" x2="21.7913" y2="74.7314"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#656565" />
              <stop offset="1" stop-color="#EAEAEA" />
            </linearGradient>
            <linearGradient id="paint7_linear_908_28791" x1="20.7039" y1="65.6119" x2="25.4731" y2="65.6119"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#656565" />
              <stop offset="1" stop-color="#EAEAEA" />
            </linearGradient>
          </defs>
        </svg>

        <span class="cart-icon__number">
          {{ aboutListItem.number }}
        </span>
      </span>
      <p class="cart-text" :class="{ 'webWidth': aboutListItem.id == 4, 'ruFont': local == 'ru' }">
        <span v-if="aboutListItem.id == 4" class="cart-text__web">{{ $t('about.web') }}</span>
        {{ $t('about.text' + aboutListItem.id) }}
      </p>
    </a>
  </li>
</template>
<script>
import openAccordion from '@/mixins/openAccordion';

export default {
  props: ["aboutListItem"],
  mixins: [openAccordion],

  computed: {
    click() {
      return (Object.entries(openAccordion.methods) || {})
        .find(item => item?.[0] == this.aboutListItem?.click)?.[1] ?? ''
    },

    local() {
      return localStorage?.getItem('lang') || 'en'
    },

    isMobile() {
      return window?.innerWidth <= 1290 || false
    }
  }
}
</script>
<style scoped lang="scss">
.about-list__item {
  display: flex;
  flex-direction: column;
  position: relative;
}

.about-list__item-lighter {
  position: absolute;
  top: -294px;
  height: 296.153px;
  width: 200%;
  left: -120px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../../public/images/about_lighter.webp");
  opacity: 0;
}

.about-list__item:hover .about-list__item-lighter {
  opacity: 1;
  transition: opacity .3s ease;
}

.about-list__item-cart {
  position: relative;
  width: 100%;
  height: 228px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='279' height='230' viewBox='0 0 279 230' fill='none'%3E%3Cpath opacity='0.28' d='M44.9288 5.61346L4.06692 54.0105C2.08645 56.3562 1 59.3271 1 62.397V213.803C1 220.911 6.70874 226.701 13.816 226.801L164.271 228.932C167.383 228.976 170.407 227.902 172.794 225.906L216.139 189.664C218.478 187.708 221.429 186.637 224.478 186.637H265C272.18 186.637 278 180.816 278 173.637V14C278 6.8203 272.18 1 265 1H54.8618C51.033 1 47.3988 2.68789 44.9288 5.61346Z' stroke='url(%23paint0_linear_483_6816)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_483_6816' x1='139.5' y1='1' x2='139.5' y2='229' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23D3D3D3'/%3E%3Cstop offset='0.28125' stop-color='%232B2B2B'/%3E%3Cstop offset='0.619792' stop-color='%234E4D4D'/%3E%3Cstop offset='1' stop-color='%23848282'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}

.about-list__item-cart .cart-icon {
  position: absolute;
  top: 26px;
  right: 35px;
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
}

.about-list__item-cart .cart-icon .svg {
  width: 99.486px;
  height: 99.485px;
}

.about-list__item-cart .cart-icon svg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
}

.about-list__item-cart .cart-icon__number {
  position: absolute;
  top: 41px;
  right: 88%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 15px;
  background: #241D30;
  color: #FFF;
  font-family: SpaceMono;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.about-list__item-cart .cart-icon__number:not(:last-child) {
  left: -10px;
}


.about-list__item-cart .cart-icon svg #paint0_linear_908_28558 stop:first-child,
.about-list__item-cart .cart-icon svg #paint1_linear_908_28558 stop:first-child,
.about-list__item-cart .cart-icon svg #paint2_linear_908_28558 stop:first-child,
.about-list__item-cart .cart-icon svg #paint3_linear_908_28558 stop:first-child,

.about-list__item-cart .cart-icon svg #paint0_linear_908_28609 stop:first-child,
.about-list__item-cart .cart-icon svg #paint1_linear_908_28609 stop:first-child,
.about-list__item-cart .cart-icon svg #paint2_linear_908_28609 stop:first-child,
.about-list__item-cart .cart-icon svg #paint3_linear_908_28609 stop:first-child,

.about-list__item-cart .cart-icon svg #paint0_linear_908_28745 stop:first-child,
.about-list__item-cart .cart-icon svg #paint1_linear_908_28745 stop:first-child,

.about-list__item-cart .cart-icon svg #paint0_linear_908_28791 stop:first-child,
.about-list__item-cart .cart-icon svg #paint1_linear_908_28791 stop:first-child,
.about-list__item-cart .cart-icon svg #paint2_linear_908_28791 stop:first-child,
.about-list__item-cart .cart-icon svg #paint3_linear_908_28791 stop:first-child,
.about-list__item-cart .cart-icon svg #paint4_linear_908_28791 stop:first-child,
.about-list__item-cart .cart-icon svg #paint5_linear_908_28791 stop:first-child,
.about-list__item-cart .cart-icon svg #paint6_linear_908_28791 stop:first-child,
.about-list__item-cart .cart-icon svg #paint7_linear_908_28791 stop:first-child {
  stop-color: #656565;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.about-list__item-cart .cart-icon svg #paint0_linear_908_28558 stop:last-child,
.about-list__item-cart .cart-icon svg #paint1_linear_908_28558 stop:last-child,
.about-list__item-cart .cart-icon svg #paint2_linear_908_28558 stop:last-child,
.about-list__item-cart .cart-icon svg #paint3_linear_908_28558 stop:last-child,

.about-list__item-cart .cart-icon svg #paint0_linear_908_28609 stop:last-child,
.about-list__item-cart .cart-icon svg #paint1_linear_908_28609 stop:last-child,
.about-list__item-cart .cart-icon svg #paint2_linear_908_28609 stop:last-child,
.about-list__item-cart .cart-icon svg #paint3_linear_908_28609 stop:last-child,

.about-list__item-cart .cart-icon svg #paint0_linear_908_28745 stop:last-child,
.about-list__item-cart .cart-icon svg #paint1_linear_908_28745 stop:last-child,

.about-list__item-cart .cart-icon svg #paint0_linear_908_28791 stop:last-child,
.about-list__item-cart .cart-icon svg #paint1_linear_908_28791 stop:last-child,
.about-list__item-cart .cart-icon svg #paint2_linear_908_28791 stop:last-child,
.about-list__item-cart .cart-icon svg #paint3_linear_908_28791 stop:last-child,
.about-list__item-cart .cart-icon svg #paint4_linear_908_28791 stop:last-child,
.about-list__item-cart .cart-icon svg #paint5_linear_908_28791 stop:last-child,
.about-list__item-cart .cart-icon svg #paint6_linear_908_28791 stop:last-child,
.about-list__item-cart .cart-icon svg #paint7_linear_908_28791 stop:last-child {
  stop-color: #EAEAEA;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.about-list__item-cart .cart-text {
  position: absolute;
  left: 34px;
  bottom: 28px;
  max-width: 153px;
  color: #FFF;
  font-family: ZenDots;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ruFont {
  font-family: Gilroy-Medium !important;
}

.webWidth {
  max-width: 95px !important;
}

.about-list__item-cart .cart-text__web {
  text-transform: uppercase;
}

.about-list__item-cart:hover .cart-icon svg #paint0_linear_908_28558 stop:first-child,
.about-list__item-cart:hover .cart-icon svg #paint1_linear_908_28558 stop:first-child,
.about-list__item-cart:hover .cart-icon svg #paint2_linear_908_28558 stop:first-child,
.about-list__item-cart:hover .cart-icon svg #paint3_linear_908_28558 stop:first-child,

.about-list__item-cart:hover .cart-icon svg #paint0_linear_908_28609 stop:first-child,
.about-list__item-cart:hover .cart-icon svg #paint1_linear_908_28609 stop:first-child,
.about-list__item-cart:hover .cart-icon svg #paint2_linear_908_28609 stop:first-child,
.about-list__item-cart:hover .cart-icon svg #paint3_linear_908_28609 stop:first-child,

.about-list__item-cart:hover .cart-icon svg #paint0_linear_908_28745 stop:first-child,
.about-list__item-cart:hover .cart-icon svg #paint1_linear_908_28745 stop:first-child,

.about-list__item-cart:hover .cart-icon svg #paint0_linear_908_28791 stop:first-child,
.about-list__item-cart:hover .cart-icon svg #paint1_linear_908_28791 stop:first-child,
.about-list__item-cart:hover .cart-icon svg #paint2_linear_908_28791 stop:first-child,
.about-list__item-cart:hover .cart-icon svg #paint3_linear_908_28791 stop:first-child,
.about-list__item-cart:hover .cart-icon svg #paint4_linear_908_28791 stop:first-child,
.about-list__item-cart:hover .cart-icon svg #paint5_linear_908_28791 stop:first-child,
.about-list__item-cart:hover .cart-icon svg #paint6_linear_908_28791 stop:first-child,
.about-list__item-cart:hover .cart-icon svg #paint7_linear_908_28791 stop:first-child {
  stop-color: #4776E6;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.about-list__item-cart:hover .cart-icon svg #paint0_linear_908_28558 stop:last-child,
.about-list__item-cart:hover .cart-icon svg #paint1_linear_908_28558 stop:last-child,
.about-list__item-cart:hover .cart-icon svg #paint2_linear_908_28558 stop:last-child,
.about-list__item-cart:hover .cart-icon svg #paint3_linear_908_28558 stop:last-child,

.about-list__item-cart:hover .cart-icon svg #paint0_linear_908_28609 stop:last-child,
.about-list__item-cart:hover .cart-icon svg #paint1_linear_908_28609 stop:last-child,
.about-list__item-cart:hover .cart-icon svg #paint2_linear_908_28609 stop:last-child,
.about-list__item-cart:hover .cart-icon svg #paint3_linear_908_28609 stop:last-child,

.about-list__item-cart:hover .cart-icon svg #paint0_linear_908_28745 stop:last-child,
.about-list__item-cart:hover .cart-icon svg #paint1_linear_908_28745 stop:last-child,

.about-list__item-cart:hover .cart-icon svg #paint0_linear_908_28791 stop:last-child,
.about-list__item-cart:hover .cart-icon svg #paint1_linear_908_28791 stop:last-child,
.about-list__item-cart:hover .cart-icon svg #paint2_linear_908_28791 stop:last-child,
.about-list__item-cart:hover .cart-icon svg #paint3_linear_908_28791 stop:last-child,
.about-list__item-cart:hover .cart-icon svg #paint4_linear_908_28791 stop:last-child,
.about-list__item-cart:hover .cart-icon svg #paint5_linear_908_28791 stop:last-child,
.about-list__item-cart:hover .cart-icon svg #paint6_linear_908_28791 stop:last-child,
.about-list__item-cart:hover .cart-icon svg #paint7_linear_908_28791 stop:last-child { 
  stop-color: #8E54E9;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

@media (min-width: 1800px) {
  .about-list__item-lighter {
    width: 175%;
    height: 327px;
    top: -324px;
    left: -117px;
  }

  .about-list__item-cart {
    min-height: 310px;
  }

  .about-list__item-cart .cart-icon {
    top: 36px;
    right: 30px;
  }

  .about-list__item-cart .cart-icon .svg {
    width: 155px;
    height: 154.001px;
  }

  .about-list__item-cart .cart-icon__number {
    top: 58px;
    width: 64px;
    height: 26px;
    font-size: 14px;
    right: 83%;
  }

  .about-list__item-cart .cart-icon__number:not(:last-child) {
    left: -10px;
  }

  .about-list__item-cart .cart-text {
    font-size: 18px;
    max-width: 185px;
    left: 40px;
    bottom: 40px;
  }

  .about-list__item-cart .cart-text__web {
    font-size: 18px;
  }

  .about-list__item:first-child .cart-icon {
    right: 49px;
  }

  .about-list__item-cart .cart-icon.Anonymous,
  .about-list__item-cart .cart-icon.Alerts {
    right: 50px;
  }

  .cart-icon.Web .svg {
    width: 166px;
    height: 166px;
  }
}

@media (min-width: 0px) and (max-width: 1290px) {
  .about-list__item-lighter {
    display: none;
  }

  .about-list__item-cart {
    height: 120.103px;
  }

  .about-list__item-cart .cart-icon {
    top: 13px;
    right: 15px;
  }

  .about-list__item-cart .cart-icon .svg {
    width: 52.406px;
    height: 52.407px;
  }

  .about-list__item-cart .cart-icon__number {
    top: 21px;
    width: 26px;
    height: 10px;
    font-size: 8px;
  }

  .about-list__item-cart .cart-icon__number:not(:last-child) {
    left: -10px;
  }

  .about-list__item-cart .cart-text {
    font-size: 10px;
    max-width: 103px;
    left: 7px;
    bottom: 14px;
  }

  .about-list__item-cart .cart-text__web {
    font-size: 10px;
  }

  .about-list__item-cart .cart-icon.Web {
    right: 24px;
  }

  .about-list__item-cart .cart-icon.Anonymous {
    right: 18px;
  }
}

@media (max-width: 680px) {
  .about-list__item-cart {
    z-index: 1;
    min-height: 115px;
  }

  .about-list__item-lighter {
    top: -139px !important;
    width: 121.876px !important;
    height: 145.501px !important;
    left: 18px !important;
  }

  .about-list__item-cart .cart-icon .svg {
    width: 49.984px;
    height: 49.985px;
  }

  .about-list__item-cart .cart-icon.Anonymous {
    right: 18px;
  }

  .about-list__item-cart .cart-icon.Web {
    right: 21px;
  }
}
</style>
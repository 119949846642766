// Copyright 2023, Alexander Nekrasov, All rights reserved.
export async function getFirebaseAuth() {
    const { getAuth, signInAnonymously, signInWithCustomToken, signOut, onAuthStateChanged, getIdToken } = await import(
    /* webpackChunkName: "firebase_auth" */ "firebase/auth");
    return {
        getAuth,
        getIdToken,
        onAuthStateChanged,
        signInAnonymously,
        signInWithCustomToken,
        signOut,
    };
}
export async function getFirebaseFunctions() {
    const { getFunctions, httpsCallable } = await import(
    /* webpackChunkName: "firebase_functions" */ "firebase/functions");
    const { FirebaseError } = await import(/* webpackChunkName: "firebase_functions" */ "@firebase/util");
    return {
        FirebaseError,
        getFunctions,
        httpsCallable,
    };
}
export async function getFirebaseFirestore() {
    const { collection, query, where, onSnapshot, getFirestore, deleteField, doc, getDoc, getDocs, setDoc, deleteDoc, limit, orderBy, updateDoc, runTransaction, writeBatch, } = await import(/* webpackChunkName: "firebase_firestore" */ "firebase/firestore");
    return {
        collection,
        deleteDoc,
        doc,
        deleteField,
        getDoc,
        getDocs,
        getFirestore,
        limit,
        orderBy,
        onSnapshot,
        query,
        runTransaction,
        setDoc,
        updateDoc,
        where,
        writeBatch,
    };
}

<template>
    <div class="ac" @click="click">
        <input :id="utpItem.id" name="accordion-1" type="radio" :checked="utpItem.checked" />
        <label :for="utpItem.id">
            <span class="ac-title">
                <span class="Web" v-if="utpItem.id == 'ac-4'">WEB 3</span> {{ $t('utp.title' + ' ' + utpItem.id) }}
            </span>
            <span class="ac-title__down">
                <span class="ac-protocol">Protocol<br>code</span>
                <span class="ac-number__first">{{ utpItem.numberFirst }} </span>
                <span class="ac-number__second">{{ utpItem.numberSecond }} </span>
            </span>
        </label>
        <article>
            <p>
                <span v-if="utpItem.id == 'ac-1'">
                    {{ $t('utp.description') }}<br><br>
                </span>
                {{ $t('utp.description' + ' ' + utpItem.id) }}
            </p>
        </article>
    </div>
</template>
<script>
export default {
    props: {
        utpItem: {},
        chooseSafe: Function,
        chooseAnon: Function,
        chooseAlerts: Function,
        chooseWeb: Function,
    },

    computed: {
        click() {
            return (Object.entries(this.$props) || {})
                .find(item => item?.[0] == this.utpItem?.click)?.[1] ?? ''
        }
    }
}
</script>
<style scoped lang="scss">
.ac-container label {
    position: relative;
    z-index: 20;
    display: flex;
    flex-direction: column;
    height: -moz-max-content;
    height: max-content;
    cursor: pointer;
}

.ac-container label .ac-title {
    color: #fff;
    font-family: Gilroy-Regular;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Web {
    font-family: ZenDots;
}

.ac-container label .ac-title__web {
    color: #FFF;
    font-family: ZenDots;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;
}

.ac-container label .ac-title__down {
    padding-top: 7px;
    border-top: 1px solid;
    border-color: #fff;
    display: flex;
    max-width: 414px;
    justify-content: space-between;
    align-items: center;
}

.ac-container label .ac-title__down .ac-protocol {
    color: #FFF;
    font-family: Gilroy-Regular;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 113.6%;
    text-transform: uppercase;
    flex: 1 1 50%;
}

.ac-container label .ac-title__down .ac-number__first,
.ac-container label .ac-title__down .ac-number__second {
    color: #FFF;
    font-family: SpaceMono;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    flex: 1 1 25%;
    text-align: center;
}

.ac-container label::after {
    position: absolute;
    display: block;
    height: 55px;
    width: 55px;
    content: "";
    right: 0;
    top: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='57' height='57' viewBox='0 0 57 57' fill='none'%3E%3Cpath d='M56 1H1V56H47.75L56 47.75V1Z' stroke='white'/%3E%3Cpath d='M28.5 20.9375V36.0625' stroke='white'/%3E%3Cpath d='M36.0625 28.5H20.9375' stroke='white'/%3E%3C/svg%3E");
}

.ac-container input:checked+label::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='57' height='57' viewBox='0 0 57 57' fill='none'%3E%3Cpath d='M56 1H1V56H47.75L56 47.75V1Z' stroke='%239465E1'/%3E%3Cpath d='M36.0625 28.5H20.9375' stroke='%239465E1'/%3E%3C/svg%3E");
}

.ac-container input:not(input:checked)+label:hover::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='57' height='57' viewBox='0 0 57 57' fill='none'%3E%3Cpath d='M55.9912 1H0.991211V56H47.7412L55.9912 47.75V1Z' stroke='%239465E1'/%3E%3Cpath d='M28.4912 20.9375V36.0625' stroke='%239465E1'/%3E%3Cpath d='M36.0537 28.5H20.9287' stroke='%239465E1'/%3E%3C/svg%3E");
}

.ac-container input:checked+label .ac-title__down,
.ac-container input+label:hover .ac-title__down {
    border-color: #9465E1;
}

.ac-container label:hover:after,
.ac-container input:checked+label:hover:after {
    content: "";
    position: absolute;
    width: 55px;
    height: 55px;
    right: 0px;
    top: 0px;
}

.ac-container input {
    display: none;
}


.ac-container article {
    margin-top: -1px;
    overflow: hidden;
    height: 0px;
    opacity: 0;
    position: relative;
    z-index: 10;
    transition: height 0.3s ease-in-out, box-shadow 0.6s linear;
}

@supports (-webkit-touch-callout: none) {
    .ac-container article {
        transition: none !important;
    }
}

.ac-container input:checked~article {
    height: 200px;
    max-height: -moz-max-content;
    max-height: max-content;
    opacity: 1;
    max-width: 405px;
}


.ac-container article p {
    color: #FFF;
    font-family: Gilroy-Regular;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 116%;
    margin-top: 40px;
}

@media (min-width: 1800px) {
    .ac-container label .ac-title {
        font-size: 25px;
    }

    .ac-container label .ac-title__web {
        font-size: 25px;
    }

    .ac-container label .ac-title__down {
        max-width: 543.246px;
    }

    .ac-container label .ac-title__down .ac-protocol {
        font-size: 12px;
    }

    .ac-container input:checked~article {
        height: 226px;
    }

    .ac-container label .ac-title__down .ac-number__first,
    .ac-container label .ac-title__down .ac-number__second {
        font-size: 12px;
    }

    .ac-container label::after {
        width: 72.17px;
        height: 72.17px;
    }

    .ac-container label:hover:after,
    .ac-container input:checked+label:hover:after {
        width: 72.17px;
        height: 72.17px;
    }

    .ac-container article p {
        font-size: 18px;
        margin-top: 40px;
    }
}

@media (min-width: 0px) and (max-width: 1290px) {
    .ac-container label .ac-title {
        font-size: 15px;
        height: 20px;
    }

    .ac-container label .ac-title__web {
        font-size: 15px;
    }

    .ac-container label .ac-title__down {
        max-width: 589px;
    }

    .ac-container label .ac-title__down .ac-protocol {
        flex: 1 1 40%;
    }

    .ac-container label .ac-title__down .ac-number__first,
    .ac-container label .ac-title__down .ac-number__second {
        text-align: start;
        margin-top: -6px;
    }

    .ac-container label .ac-title__down .ac-number__first {
        flex: 1 1 10%;
    }

    .ac-container label .ac-title__down .ac-number__second {
        flex: 1 1 38%;
    }

    .ac-container label::after {
        top: -6px;
    }

    .ac-container label:hover:after,
    .ac-container input:checked+label:hover:after {
        top: -6px;
    }

    .ac-container input:checked~article {
        max-width: 521px;
    }

    .ac-container article p {
        margin-top: 30px;
        font-size: 12px;
    }
}

@media (max-width: 680px) {
    .ac-container label .ac-title__down {
        max-width: 260px;
    }

    .ac-container label .ac-title__down .ac-number__first {
        flex: 1 1 18%;
    }

    .ac-container label .ac-title__down .ac-number__second {
        flex: 1 1 22%;
    }

    .ac-container label::after {
        top: 0px;
        width: 41px;
        height: 41px;
    }

    .ac-container label:hover:after,
    .ac-container input:checked+label:hover:after {
        top: 0px;
        width: 41px;
        height: 41px;
    }

    .ac-container input:checked~article {
        max-width: 263px;
    }

    .ac-container article p {
        margin-top: 30px;
    }
}
</style>
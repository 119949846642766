// Copyright 2023, Alexander Nekrasov, All rights reserved.

import { getWalletEthereum, getWalletPhantom, getWalletDummy } from "../code/global";
import { walletProxy } from "../code/walletProxy";

export default {
  install(app /*, options*/) {
    if (app.config?.globalProperties) {
      //console.log("Vue3");
      app.config.globalProperties.$wallet = walletProxy;
      app.config.globalProperties.$metamaskWallet = getWalletEthereum();
      app.config.globalProperties.$phantomWallet = getWalletPhantom();
      app.config.globalProperties.$dummyWallet = getWalletDummy();
    } else if (app.prototype) {
      //console.log("Vue2");
      app.prototype.$wallet = walletProxy;
      app.prototype.$metamaskWallet = getWalletEthereum();
      app.prototype.$phantomWallet = getWalletPhantom();
      app.prototype.$dummyWallet = getWalletDummy();
    }
  },
};

// Copyright 2023, Alexander Nekrasov, All rights reserved.

import { GetEthers } from "./ethersChunk";

// prettier-ignore
const erc20ABI = [
  "function decimals() public view returns (uint8)",
  "function allowance(address owner, address spender) public view returns (uint256)",
  "function approve(address spender, uint256 amount) public returns (bool)",
  "function mint(address for_, uint256 amount)",
  "function balanceOf(address account) public view returns (uint256)"
];

export async function erc20GetDecimals(ethereumProvider, contractAddress) {
  if (!ethereumProvider) throw "missing ethereumProvider";
  if (!contractAddress) throw "missing contractAddress";

  const ethers = await GetEthers();
  const provider = new ethers.providers.Web3Provider(ethereumProvider);
  const contract = new ethers.Contract(contractAddress, erc20ABI, provider);
  return await contract.decimals();
}

export async function erc20GetBalance(ethereumProvider, contractAddress, walletAddress) {
  if (!ethereumProvider) throw "missing ethereumProvider";
  if (!contractAddress) throw "missing contractAddress";
  if (!walletAddress) throw "missing walletAddress";

  const ethers = await GetEthers();
  const provider = new ethers.providers.Web3Provider(ethereumProvider);
  const contract = new ethers.Contract(contractAddress, erc20ABI, provider);
  return await contract.balanceOf(walletAddress);
}

export async function erc20GetAllowance(ethereumProvider, contractAddress, walletAddress, spenderAddress) {
  if (!ethereumProvider) throw "missing ethereumProvider";
  if (!contractAddress) throw "missing contractAddress";
  if (!walletAddress) throw "missing walletAddress";
  if (!spenderAddress) throw "missing spenderAddress";

  const ethers = await GetEthers();
  const provider = new ethers.providers.Web3Provider(ethereumProvider);
  const contract = new ethers.Contract(contractAddress, erc20ABI, provider);
  return await contract.allowance(walletAddress, spenderAddress);
}

export async function createApproveTxData(spenderAddress, amount) {
  const ethers = await GetEthers();
  const iface = new ethers.utils.Interface(erc20ABI);
  const txData = iface.encodeFunctionData("approve", [spenderAddress, amount]);
  return txData;
}

// export async function writeExample(contractAddress, ethereumProvider, value) {
//   const ethers = await GetEthers();
//   const provider = new ethers.providers.Web3Provider(ethereumProvider);
//   const signer = provider.getSigner();
//   const contract = new ethers.Contract(contractAddress, donatrixABI, signer);
//   const serviceFee = await contract.GetFeePercent();
//   return Number(serviceFee) / 100;
// }

// Copyright 2023, Alexander Nekrasov, All rights reserved.
export class FiatValue {
    constructor(value) {
        this.usd = Number(value?.usd) || 0;
        this.eur = Number(value?.eur) || 0;
        this.rub = Number(value?.rub) || 0;
    }
}
export class MessageSummaryItem {
    constructor(id, startDate, endDate, value) {
        this.id = id;
        this.numTransactions = value?.numTransactions || 0;
        this.fiatValue = new FiatValue(value?.fiatValue);
        this.startDate = new Date(startDate);
        this.endDate = new Date(endDate);
    }
}

export default {
    methods: {
        openSafe() {
            const open = document.querySelector('#ac-1')
            open.click()
        },

        openAnonymous() {
            const open = document.querySelector('#ac-2')
            open.click()
        },

        openAlerts() {
            const open = document.querySelector('#ac-3')
            open.click()
        },

        openWeb() {
            const open = document.querySelector('#ac-4')
            open.click()
        },   
    }
}

<template>
    <ul class="blockchain-list">
        <BlockchainNetworksItem :blockchainNetwork="blockchainNetwork" v-for="blockchainNetwork in blockchainNetworks" :key="blockchainNetwork.id" />
    </ul>
</template>
<script>
import BlockchainNetworksItem from '@/components/landing/blockchainNetworksItem.vue';
import { networks } from '@/data/blockchainNetworks'

export default {
    components: { BlockchainNetworksItem },

    computed: {
        blockchainNetworks() {
            return networks
        }
    }
}
</script>
<style scoped lang="scss">
.blockchain-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0;
}

@media (min-width: 1800px) {
    .blockchain-list {
        gap: 30px;
        margin-left: 14px;
    }
}

@media (max-width: 680px) {
    .blockchain-list {
        grid-template-columns: repeat(3, 1fr);
        gap: 26px 0px;
    }
}
</style>
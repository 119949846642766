<template>
  <ul class="about-list__items">
    <AboutListItem :aboutListItem="aboutListItem" v-for="aboutListItem in aboutListItems" :key="aboutListItem.id" />
  </ul>
</template>
<script>
import AboutListItem from '@/components/landing/aboutListItem';
import { aboutListItems } from '@/data/aboutListItems';

export default {
  computed: {
    aboutListItems() {
      return aboutListItems
    }
  },

  components: { AboutListItem },
}
</script>
<style scoped lang="scss">
.about-list__items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 33px;
  position: relative;
  padding-top: 102px;
  z-index: 0;
}

@media (min-width: 1800px) {
  .about-list__items {
    gap: 54px;
  }
}

@media (min-width: 0px) and (max-width: 1290px) {
  .about-list__items {
    padding-top: 42px;
    gap: 21px;
  }
}

@media (max-width: 680px) {
  .about-list__items {
    padding-top: 37px;
    grid-template-columns: repeat(2, 1fr);
    gap: 27px 21px;
  }
}
</style>
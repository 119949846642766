<template>
    <div id="ac-container" class="ac-container">
        <UtpItem :utpItem="utpItem" v-for="utpItem in utpItems" :key="utpItem.id" :choose-safe="chooseSafe"
            :choose-anon="chooseAnon" :choose-alerts="chooseAlerts" :choose-web="chooseWeb" />
    </div>
</template>
<script>
import UtpItem from '@/components/landing/utpItem';
import { utpItems } from '@/data/utpItems';

export default {
    props: {
        chooseSafe: Function,
        chooseAnon: Function,
        chooseAlerts: Function,
        chooseWeb: Function,
    },

    components: { UtpItem },

    computed: {
        utpItems() {
            return utpItems
        }
    }
}
</script>
<style scoped lang="scss">
.ac-container {
    position: relative;
    top: 311px;
    width: 469px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.ac-container .ac:not(:last-child) {
    margin-bottom: 52px;
}

@media (min-width: 1800px) {
    .ac-container {
        top: 248px;
        width: 615.42px;
    }

    .ac-container .ac:not(:last-child) {
        margin-bottom: 79px;
    }
}

@media (min-width: 0px) and (max-width: 1290px) {
    .ac-container {
        top: 108px;
        width: 644px;
        margin-left: 4px;
    }

    .ac-container .ac:not(:last-child) {
        margin-bottom: 36px;
    }
}

@media (max-width: 680px) {
    .ac-container {
        top: 76px;
        width: 300px;
        margin-left: 0;
    }

    .ac-container .ac:not(:last-child) {
        margin-bottom: 39px;
    }
}
</style>
import { render, staticRenderFns } from "./planet.vue?vue&type=template&id=394c0794&scoped=true"
import script from "./planet.vue?vue&type=script&lang=js"
export * from "./planet.vue?vue&type=script&lang=js"
import style0 from "./planet.vue?vue&type=style&index=0&id=394c0794&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "394c0794",
  null
  
)

export default component.exports
// Copyright 2023, Alexander Nekrasov, All rights reserved.

//* global BigInt */
import { encode } from "bs58";
import { SafeMath } from "../safeMath";
import { WalletBase, WalletType } from "./WalletBase";
import { WalletUserRejectedError, WalletValidationError, WalletUnexpectedError } from "./WalletError";
import { GetSolana, GetSplToken } from "../solanaChunk";

const NATIVE_TOKEN = "11111111111111111111111111111111";
const MAX_FEE = 10000n;

export class WalletPhantom extends WalletBase {
  constructor() {
    super("Phantom", WalletType.Solana);

    this.noConnectionMode = true;
    this.publicKey = undefined;
    this.chainId = undefined;
    this.connection = undefined;

    // overriding base class stuff
    this.zeroAddress = NATIVE_TOKEN;
    this.redirectToHomePageImpl = this.RedirectToHomePageImpl.bind(this);
    this.connectImpl = this.ConnectImpl.bind(this);
    this.personalSignImpl = this.PersonalSignImpl.bind(this);
    this.sendTxImpl = this.SendTxDataImpl.bind(this);
    // this.sendApproveTxImpl = this.SendApproveTxImpl.bind(this);
    this.sendDonateTxImpl = this.SendDonateTxImpl.bind(this);
    this.changeNetworkImpl = this.ChangeNetworkImpl.bind(this);
    this.getAttached = () => {
      return (
        this.sharedState.publicInitialized === true &&
        this.address !== undefined &&
        this.address === this.sharedState.accountSolanaAddress
      );
    };
    this.getAttachable = () => {
      return (
        this.sharedState.publicInitialized === true &&
        this.address !== undefined &&
        this.sharedState.accountSolanaAddress === undefined
      );
    };
    this.getDetachable = () => {
      return this.sharedState.publicInitialized === true && this.sharedState.accountSolanaAddress !== undefined;
    };

    this.getBalanceImpl = this.GetBalanceImpl.bind(this);
    this.getTokenBalanceImpl = this.GetTokenBalanceImpl.bind(this);
    this.getTokenAllowanceImpl = this.GetTokenAllowanceImpl.bind(this);
  }

  get destinationAddressField() {
    return "solanaAddress";
  }

  async PostConstruct() {
    const provider = window.phantom?.solana;
    if (!provider) return;

    this.provider = provider;
    this.detected = provider !== undefined;

    // this is because of stupid Phantom doesn't have nice emitter for when account is CHANGED
    const connFunc = () => {
      this.provider.connect({ onlyIfTrusted: true }).catch(() => {});
    };
    setTimeout(connFunc, 50);
    setInterval(connFunc, 500);

    // this emits only when application is disconnected
    provider.on("accountChanged", this.OnAccountChanged.bind(this));
    provider.on("connect", this.OnAccountChanged.bind(this));

    this.ChangeNetwork("devnet");
  }

  OnAccountChanged(publicKey) {
    if (!publicKey) {
      this.publicKey = undefined;
      this.address = undefined;
      return;
    }

    if (this.address === publicKey.toBase58()) return; // all good, still there
    this.publicKey = publicKey;
    this.address = publicKey.toBase58();
  }

  RedirectToHomePageImpl() {
    window.open("https://phantom.app/", "_blank");
  }

  async ConnectImpl() {
    if (this.address) throw { error: "stateError", stateError: "already connected" };
    if (this.isPendingConnect) throw { error: "stateError", stateError: "another request in progress" };
    if (!this.provider) {
      throw { error: "stateError", stateError: "no provider" };
    }

    this.isPendingConnect = true;
    try {
      const resp = await this.provider.request({ method: "connect" });
      this.isPendingConnect = false;
      this.OnAccountChanged(resp.publicKey);

      // ??? if (!this.chainId) this.FetchChainId();
      this.Activate();
    } catch (err) {
      this.isPendingConnect = false;
      if (err.code === 4001) {
        throw { error: "rejected" };
      }
      throw { error: "unexpectedError", unexpectedError: err };
    }
  }

  async PersonalSignImpl(challenge) {
    if (!this.address || typeof challenge !== "string") return;
    this.isPendingSign = true;
    const buffer = new TextEncoder().encode(challenge);
    try {
      const signedMessage = await this.provider.request({
        method: "signMessage",
        params: {
          message: buffer,
          display: "utf8", //hex,utf8
        },
      });
      this.isPendingSign = false;
      return encode(signedMessage.signature);
    } catch (error) {
      this.isPendingSign = false;
      throw error;
    }
  }

  async SendTxDataImpl(transaction, txHashCallback) {
    if (!this.address) return; //error
    if (this.isPendingTx) return;
    this.isPendingTx = true;

    try {
      //const connection = await this.GetConnection();
      //const { blockhash } = await connection.getLatestBlockhash();
      const { blockhash } = await this.sharedState.getSolanaLatestBlockhash(this.chainId);
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = this.publicKey;

      const { signature } = await this.provider.signAndSendTransaction(transaction);
      if (typeof txHashCallback === "function") {
        txHashCallback(signature);
      }
      console.log(signature);
      this.isPendingTx = false;
      return signature;
    } catch (error) {
      this.isPendingTx = false;
      if (error?.code === 4001 || error?.code === "ACTION_REJECTED") {
        throw new WalletUserRejectedError();
      } else {
        throw new WalletUnexpectedError(error);
      }
    }
  }

  // this.sendApproveTxImpl = this.SendApproveTxImpl.bind(this);

  async SendDonateTxImpl(
    recipientAddress,
    tokenAddress,
    rawAmount,
    feeUnits,
    params = { feeAddress: undefined },
    txHashCallback
  ) {
    if (!recipientAddress) throw new WalletValidationError("recipientAddress is required");
    if (!tokenAddress) throw new WalletValidationError("tokenAddress is required");
    if (!rawAmount) throw new WalletValidationError("rawAmount is required");
    if (typeof feeUnits !== "bigint" || feeUnits > MAX_FEE) throw new WalletValidationError("invalid feeUnits");
    if (!params.feeAddress) throw new WalletValidationError("params.feeAddress is required");

    const pureValue = SafeMath.div(SafeMath.mul(rawAmount, SafeMath.sub(MAX_FEE, feeUnits)), MAX_FEE);
    const feeValue = SafeMath.sub(rawAmount, pureValue);

    const { Transaction, SystemProgram, PublicKey } = await GetSolana();
    const recipientPubkey = new PublicKey(recipientAddress);
    const feePubkey = new PublicKey(params.feeAddress);
    const transaction = new Transaction();
    if (tokenAddress === NATIVE_TOKEN) {
      transaction.add(
        SystemProgram.transfer({ fromPubkey: this.publicKey, toPubkey: recipientPubkey, lamports: pureValue })
      );
      transaction.add(SystemProgram.transfer({ fromPubkey: this.publicKey, toPubkey: feePubkey, lamports: feeValue }));
    } else {
      const { getAssociatedTokenAddressSync, createTransferInstruction } = await GetSplToken();
      const tokenMint = new PublicKey(tokenAddress);
      const accountFrom = getAssociatedTokenAddressSync(tokenMint, this.publicKey);
      const accountTo = getAssociatedTokenAddressSync(tokenMint, recipientPubkey);
      const accountFee = getAssociatedTokenAddressSync(tokenMint, feePubkey);
      transaction.add(createTransferInstruction(accountFrom, accountTo, this.publicKey, pureValue));
      transaction.add(createTransferInstruction(accountFrom, accountFee, this.publicKey, feeValue));
    }

    return this.SendTxDataImpl(transaction, txHashCallback);
  }

  async ChangeNetworkImpl(chainId) {
    this.chainId = chainId;
    this.connection = undefined;
  }

  GetConnection() {
    //if (this.noConnectionMode)
    return Promise.reject();

    // if (this.connection) return Promise.resolve(this.connection);
    // if (!this.chainId) return Promise.reject();
    // return new Promise((resolve, reject) => {
    //   GetSolana().then((module) => {
    //     const { Connection, clusterApiUrl } = module;
    //     //const url = this.chainId === "mainnet-beta" ? "<mainnet rpc url>" : this.chainId === "devnet" ? clusterApiUrl("devnet") : undefined;
    //     const url = clusterApiUrl(this.chainId);
    //     const connection = new Connection(url, "confirmed");
    //     console.log("create new connection");
    //     if (!connection) {
    //       reject();
    //     } else {
    //       this.connection = connection;
    //       resolve(connection);
    //     }
    //   });
    // });
  }

  async GetBalanceImpl() {
    // if (!this.publicKey) return 0n;
    // const connection = await this.GetConnection();
    // const balance = await connection.getBalance(this.publicKey);
    // console.log("getbalance");
    // return BigInt(balance);

    // shortcut for no-connection mode;
    return 999999999999999n;
  }

  async GetTokenBalanceImpl() {
    // shortcut for no-connection mode;
    return 999999999999999n;
  }

  async GetTokenAllowanceImpl() {
    // shortcut for no-connection mode;
    return 999999999999999n;
  }
}

// Copyright 2023, Alexander Nekrasov, All rights reserved.

export async function GetSolana() {
  const { Connection, clusterApiUrl, Transaction, SystemProgram, PublicKey } = await import(/* webpackChunkName: "solana" */ "@solana/web3.js");
  return { Connection, clusterApiUrl, Transaction, SystemProgram, PublicKey };
}

export async function GetSplToken() {
  // prettier-ignore
  const {
    getAssociatedTokenAddressSync,
    createTransferInstruction,
    createTransferCheckedInstruction,
  } = await import(/* webpackChunkName: "spl-token" */ "@solana/spl-token");
  return { getAssociatedTokenAddressSync, createTransferInstruction, createTransferCheckedInstruction };
}

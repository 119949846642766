// Copyright 2023, Alexander Nekrasov, All rights reserved.
import { SettingBool } from "./SettingBool";
import { SettingChoice } from "./SettingChoice";
import { SettingSlider } from "./SettingSlider";
export const SETTING_DOMAIN_ACCOUNT = "account";
export const SETTING_DOMAIN_PUBLIC = "public";
// make sure to match these lists to each other
const currencyList = Object.freeze(["usd", "eur", "rub"]);
const symbolList = Object.freeze(["$", "€", "₽"]);
export class AccountSettings {
    constructor() {
        this.urlFilterSetting = new SettingBool(SETTING_DOMAIN_ACCOUNT, "urlFilter", true);
        this.displayCurrencySetting = new SettingChoice(SETTING_DOMAIN_ACCOUNT, "displayCurrency", currencyList); // !!! the list must match one supported in backend
        this.calendarFormatSetting = new SettingChoice(SETTING_DOMAIN_ACCOUNT, "calendarFormat", ["auto", "mon", "sun", "sat"]);
        this.messageLimitSetting = new SettingSlider(SETTING_DOMAIN_PUBLIC, "messageLimit", 200, 0, 350, 5);
        this.saveDelegate = undefined;
    }
    get urlFilter() {
        return this.urlFilterSetting.value;
    }
    get displayCurrency() {
        return String(this.displayCurrencySetting.value);
    }
    get displayCurrencySymbol() {
        const index = currencyList.indexOf(this.displayCurrency);
        return symbolList[index];
    }
    get calendarFormat() {
        return String(this.calendarFormatSetting.value);
    }
    get messageLimit() {
        return this.messageLimitSetting.value;
    }
    get allSettings() {
        return [
            this.urlFilterSetting,
            this.displayCurrencySetting,
            this.calendarFormatSetting,
            this.messageLimitSetting,
        ];
    }
    get dirty() {
        for (let setting of this.allSettings) {
            if (setting.dirty)
                return true;
        }
        return false;
    }
    get domains() {
        return [SETTING_DOMAIN_ACCOUNT, SETTING_DOMAIN_PUBLIC];
    }
    GetDomainSettings(domain) {
        return this.allSettings.filter((setting) => setting.domain === domain);
    }
    Save() {
        if (this.saveDelegate) {
            return this.saveDelegate(this);
        }
        throw "saveDelegate is not initialized";
    }
    Revert() {
        this.allSettings.forEach((setting) => {
            setting.Revert();
        });
    }
    fromDBJson(domain, data) {
        if (!data)
            return;
        this.allSettings.forEach((setting) => {
            if (setting.domain === domain) {
                setting.fromDBJson(data);
            }
        });
    }
    toDBJson(domain) {
        const data = {};
        this.allSettings.forEach((setting) => {
            if (setting.domain === domain) {
                setting.toDBJson(data);
            }
        });
        return data;
    }
}
